"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertClientProducible = exports.isClientProducible = exports.isReducible = exports.parseAction = exports.Action = void 0;
var zod_1 = require("zod");
var broadcasts_1 = require("./broadcasts");
var chats_1 = require("./chats");
var emails_1 = require("./emails");
var events_1 = require("./events");
var feedback_1 = require("./feedback");
var issues_1 = require("./issues");
var organizations_1 = require("./organizations");
var sessions_1 = require("./sessions");
var thumbnails_1 = require("./thumbnails");
var uploads_1 = require("./uploads");
var users_1 = require("./users");
/** ACTION */
exports.Action = zod_1.z.union([
    broadcasts_1.Action,
    chats_1.Action,
    emails_1.Action,
    events_1.Action,
    feedback_1.Action,
    issues_1.Action,
    organizations_1.Action,
    sessions_1.Action,
    thumbnails_1.Action,
    uploads_1.Action,
    users_1.Action,
]);
var parseAction = function (data) { return exports.Action.parse(data); };
exports.parseAction = parseAction;
var isReducible = function (action) {
    return !("notReducible" in action && action.notReducible === true);
};
exports.isReducible = isReducible;
var isClientProducible = function (action) {
    return !("notClientProducible" in action && action.notClientProducible === true);
};
exports.isClientProducible = isClientProducible;
var assertClientProducible = function (action) {
    if ((0, exports.isClientProducible)(action)) {
        return action;
    }
    throw Error("Action cannot have property 'notClientProducible' set to true");
};
exports.assertClientProducible = assertClientProducible;
