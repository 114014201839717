import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";

import { events } from "store";

import { useStore } from "../../context/store";
import { TimePicker } from "../TimePicker";

type Props = {
  open: boolean;
  onClose: () => void;
  eid: number;
  dateId: number;
};

export const TimeSetter: React.FC<Props> = ({ eid, dateId, ...props }) => {
  const { dispatch, state } = useStore();

  const event = events.eventSelector(state, { eid });

  const [processing, setProcessing] = useState(false);

  const [lineUp, setLineUp] = useState<string>(new Date().toUTCString());
  const [start, setStart] = useState<string>(new Date().toUTCString());
  const [end, setEnd] = useState<string>(new Date().toUTCString());

  // this dialog needs to be reusable
  useEffect(() => {
    // don't overwrite if still processing
    if (!props.open || processing) {
      return;
    }
    const date = events.dateSelector(state, { eid, dateId });

    setLineUp(date.times.lineUp);
    setStart(date.times.start);
    setEnd(date.times.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const handleClose = () => !processing && props.onClose();

  const lineUpAfterStart =
    (lineUp &&
      start &&
      new Date(lineUp).getTime() > new Date(start).getTime()) ||
    false;
  const startAfterEnd =
    (start && end && new Date(start).getTime() > new Date(end).getTime()) ||
    false;

  const submittable =
    lineUp && start && end && !lineUpAfterStart && !startAfterEnd;

  const handleSubmit = () => {
    if (!submittable || processing) {
      return;
    }
    setProcessing(true);
    const action = events.updatedTimesProducer({
      eid,
      dateId,
      lineUp,
      start,
      end,
    });
    dispatch(action)
      .then(props.onClose)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const textFieldProps = {
    type: "text",
    variant: "outlined",
    fullWidth: true,
    margin: "dense",
    disabled: processing,
  } as const;

  return (
    <Dialog
      open={props.open || processing}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Set Times of '{event.name}'</DialogTitle>

      <DialogContent>
        <TimePicker
          label="Line-Up"
          time={lineUp}
          onTimeChange={setLineUp}
          textFieldProps={{
            ...textFieldProps,
            error: lineUpAfterStart,
            helperText: lineUpAfterStart
              ? "Line-up can't be later than start"
              : " ",
          }}
        />

        <TimePicker
          label="Start"
          time={start}
          onTimeChange={setStart}
          textFieldProps={{
            ...textFieldProps,
            error: startAfterEnd,
            helperText: startAfterEnd ? "Start can't be later than end" : " ",
          }}
        />

        <TimePicker
          label="End"
          time={end}
          onTimeChange={setEnd}
          textFieldProps={textFieldProps}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!submittable || processing} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
