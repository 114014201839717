import { Chip } from "@mui/material";

import { events, users } from "store";

import { useStore } from "../../context/store";

type Props = {
  eid: number;
  oid: number;
};

export const Taker: React.FC<Props> = ({ eid, oid }) => {
  const { state, dispatch } = useStore();

  const organization = state.organizations[oid];
  if (organization === undefined) {
    return (
      <Chip variant="filled" color="error" label="ORGANIZATION UNDEFINED" />
    );
  }

  const currentUser = users.currentUserSelector(state);

  const handleDelete = () => {
    dispatch(events.takerRemovedProducer({ eid, oid })).catch(alert);
  };

  return (
    <Chip
      variant="filled"
      label={organization.name}
      onDelete={currentUser?.siteAdmin ? handleDelete : undefined}
    />
  );
};
