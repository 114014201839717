import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";

import { broadcasts } from "store";

import { useStore } from "../../context/store";

export const GroupAdder: React.FC = () => {
  const { dispatch } = useStore();

  const [title, setTitle] = useState("");

  const [processing, setProcessing] = useState(false);

  const submittable = !processing && !!title;

  const handleSubmit = () => {
    if (!submittable) return;
    setProcessing(true);
    const action = broadcasts.groupAddedProducer({ title });
    dispatch(action)
      .then(() => setTitle(""))
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Card component={Stack} justifyContent="space-between" variant="outlined">
      <CardContent>
        <TextField
          fullWidth
          margin="normal"
          label="New Group"
          type="text"
          variant="outlined"
          value={title}
          onChange={({ target: { value } }) => setTitle(value)}
          disabled={processing}
          onKeyDown={({ code }) => code === "Enter" && handleSubmit()}
        />
      </CardContent>
      <Stack component={CardActions} direction="row" justifyContent="flex-end">
        <Button disabled={!submittable} onClick={handleSubmit}>
          Create
        </Button>
      </Stack>
    </Card>
  );
};
