"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = void 0;
var broadcasts_1 = require("./broadcasts");
var chats_1 = require("./chats");
var emails_1 = require("./emails");
var events_1 = require("./events");
var feedback_1 = require("./feedback");
var issues_1 = require("./issues");
var organizations_1 = require("./organizations");
var sessions_1 = require("./sessions");
var thumbnails_1 = require("./thumbnails");
var uploads_1 = require("./uploads");
var users_1 = require("./users");
var reducer = function (_a) {
    var state = _a.state, action = _a.action;
    if ("error" in action && action.error !== undefined) {
        return state;
    }
    switch (action.domain) {
        case "broadcasts":
            return (0, broadcasts_1.reducer)({ state: state, action: action });
        case "chats":
            return (0, chats_1.reducer)({ state: state, action: action });
        case "emails":
            return (0, emails_1.reducer)({ state: state, action: action });
        case "events":
            return (0, events_1.reducer)({ state: state, action: action });
        case "feedback":
            return (0, feedback_1.reducer)({ state: state, action: action });
        case "issues":
            return (0, issues_1.reducer)({ state: state, action: action });
        case "organizations":
            return (0, organizations_1.reducer)({ state: state, action: action });
        case "sessions":
            return (0, sessions_1.reducer)({ state: state, action: action });
        case "thumbnails":
            return (0, thumbnails_1.reducer)({ state: state, action: action });
        case "uploads":
            return (0, uploads_1.reducer)({ state: state, action: action });
        case "users":
            return (0, users_1.reducer)({ state: state, action: action });
    }
};
exports.reducer = reducer;
