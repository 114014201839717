import {
  Card,
  Stack,
  CardContent,
  FormControlLabel,
  Checkbox,
  TextField,
  CardActions,
  Button,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";

import { broadcasts } from "store";

import { useStore } from "../../context/store";

type Props = {
  fieldId: number;
  onClose: () => void;
};

export const FieldEditor: React.FC<Props> = ({ fieldId, onClose }) => {
  const { state, dispatch } = useStore();

  const [required, setRequired] = useState(false);
  const [name, setName] = useState("");
  const [allowed, setAllowed] = useState("");

  const field = state.broadcasts.fields[fieldId]!;
  useEffect(() => {
    const { required, allowed, name } = field;
    setRequired(required);
    setAllowed(allowed.join("\n"));
    setName(name);
  }, [field]);

  const update = useMemo(() => {
    const update: Partial<broadcasts.Field> = {};
    if (required !== field.required) {
      update.required = required;
    }
    if (name !== field.name) {
      update.name = name;
    }
    const allowedParsed = allowed
      .split("\n")
      .map((line) => line.trim())
      .filter((value) => value !== "");
    // rejoin after parsing to ensure sanitized input
    if (allowedParsed.join("\n") !== field.allowed.join("\n")) {
      update.allowed = allowedParsed;
    }
    return update;
  }, [required, name, allowed, field]);

  const [processing, setProcessing] = useState(false);
  const submittable = !processing && name && Object.keys(update).length !== 0;

  const handleSave = () => {
    if (!submittable) {
      return;
    }
    setProcessing(true);

    const action = broadcasts.fieldUpdatedProducer({ fieldId, ...update });
    dispatch(action)
      .then(onClose)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Card variant="outlined">
      <Stack component={CardContent} spacing={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={required}
              disabled={processing}
              onChange={({ target: { checked } }) => setRequired(checked)}
            />
          }
          label="Required"
        />
        <TextField
          label="Name"
          value={name}
          disabled={processing}
          onChange={({ target: { value } }) => setName(value)}
        />
        <TextField
          value={allowed}
          multiline
          label="Allowed Values"
          minRows={3}
          disabled={processing}
          helperText="One value per line. Leave empty to accept any text."
          onChange={({ target: { value } }) => setAllowed(value)}
        />
      </Stack>
      <Stack
        component={CardActions}
        direction="row"
        spacing={1}
        justifyContent="flex-end"
      >
        <Button color="warning" disabled={processing} onClick={onClose}>
          Cancel
        </Button>
        <Button color="success" disabled={!submittable} onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </Card>
  );
};
