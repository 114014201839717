import { HOC } from "../utils/hoc";
import { LocalizationProvider } from "./localization";
import { StoreContextProvider } from "./store";
import { ThemeProvider } from "./theme";

export const ContextProvider: HOC = ({ children }) => (
  <ThemeProvider>
    <LocalizationProvider>
      <StoreContextProvider>
        <>{children}</>
      </StoreContextProvider>
    </LocalizationProvider>
  </ThemeProvider>
);
