import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Help,
  Cancel,
  FlagCircle,
  CheckCircle,
  AccessTime,
  Link,
  LinkOff,
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";

import {
  events,
  organizations,
  sessions,
  users,
  definedEntries,
  feedback,
  chats,
} from "store";

import { useStore } from "../../context/store";

type Severity = "none" | "minor" | "major";

const IssueIcon: React.FC<{
  hasIssue: Severity | undefined;
}> = ({ hasIssue }) => {
  switch (hasIssue) {
    case undefined:
      return <Help />;
    case "none":
      return <CheckCircle color="success" />;
    case "minor":
      return <FlagCircle color="warning" />;
    case "major":
      return <Cancel color="error" />;
  }
};

const IssueUpdater: React.FC<{
  eid: number;
  dateId: number;
  oid: number;
  hasIssue: Severity | undefined;
}> = ({ eid, dateId, oid, hasIssue }) => {
  const { dispatch } = useStore();

  const [processing, setProcessing] = useState(false);

  const handleIssueUpdate = (value: string) => {
    setProcessing(true);
    const action = feedback.updatedProducer({
      eid,
      dateId,
      hasIssue: value as Severity,
      oid,
    });
    dispatch(action)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <FormControl>
      <FormLabel>Please Select:</FormLabel>
      <RadioGroup
        row
        value={hasIssue}
        onChange={({ target: { value } }) => handleIssueUpdate(value)}
      >
        <FormControlLabel
          value="none"
          control={<Radio />}
          label="Receiving is great"
          disabled={processing}
        />
        <FormControlLabel
          value="minor"
          control={<Radio />}
          label="Receiving but minor issues"
          disabled={processing}
        />
        <FormControlLabel
          value="major"
          control={<Radio />}
          label="Receiving nothing or major issues"
          disabled={processing}
        />
      </RadioGroup>
    </FormControl>
  );
};

const Chat: React.FC<{
  eid: number;
  dateId: number;
  oid: number;
  uid: number;
  chat: Partial<{
    [_: number]: { date: string; message: string; uid: number };
  }>;
}> = ({ eid, dateId, oid, uid, chat }) => {
  const { state, dispatch } = useStore();

  const items = definedEntries(chat ?? {}).map(([messageId, value]) => ({
    messageId,
    name: users.userSelector(state, value.uid)?.name ?? "Unknown",
    ...value,
  }));
  const groupedByDay = items.reduce((acc, item) => {
    const date = new Date(item.date);
    const day = `${date.getUTCDate()}.${
      date.getUTCMonth() + 1
    }.${date.getUTCFullYear()}`;
    if (acc[acc.length - 1]?.day !== day) {
      acc.push({ day, items: [] });
    }
    acc[acc.length - 1].items.push(item);
    return acc;
  }, [] as Array<{ day: string; items: typeof items }>);

  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const handleSendMessage = () => {
    setProcessing(true);
    const action = chats.takerMessagedProducer({
      eid,
      dateId,
      oid,
      message,
    });
    dispatch(action)
      .then(() => setMessage(""))
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const currentUser = users.userSelector(state, uid);
  const helperText =
    message === "" ? `Chat as ${currentUser!.name}` : "Press ENTER to send";

  const chatElement = useRef<any>(null);
  useEffect(() => {
    if (!chatElement.current) return;
    chatElement.current.scrollTop = chatElement.current.scrollHeight;
  }, [groupedByDay, chatElement]);

  return (
    <>
      <List
        ref={chatElement}
        dense
        subheader={<ListItem />}
        component={Box}
        position="relative"
        overflow="auto"
        maxHeight={200}
      >
        {groupedByDay.map(({ day, items }) => (
          <>
            <ListSubheader>{day}</ListSubheader>
            {items.map(({ date, messageId, message, name }) => (
              <ListItem key={messageId}>
                <ListItemText
                  primary={message}
                  secondary={`${name}, ${new Date(date).toLocaleTimeString([], {
                    timeZone: "UTC",
                    timeStyle: "medium",
                  })}`}
                />
              </ListItem>
            ))}
          </>
        ))}
      </List>
      <TextField
        type="text"
        fullWidth
        margin="dense"
        variant="standard"
        disabled={processing}
        helperText={helperText}
        placeholder="Type here..."
        onChange={({ target: { value } }) => setMessage(value)}
        onKeyDown={({ code }: { code: string }) =>
          code === "Enter" && handleSendMessage()
        }
      >
        {message}
      </TextField>
    </>
  );
};

export const Taker: React.FC<{
  eid: number;
  dateId: number;
  oid: number;
}> = ({ eid, dateId, oid }) => {
  const { state } = useStore();

  const currentUser = users.currentUserSelector(state);
  const { uid } = currentUser!;

  const date = events.dateSelector(state, { eid, dateId });
  const chatId = date.chats[oid];
  const chat = chatId === undefined ? {} : state.chats[chatId]?.messages ?? {};
  const feedbackId = date.feedback[oid];
  const feedback =
    feedbackId !== undefined ? state.feedback[feedbackId] : undefined;

  const organization = organizations.organizationSelector(state, oid)!;

  const isMember = uid in organization.users;

  const connectedTakerNames = organizations
    .userIdsSelector(state, oid)!
    .filter(
      (uid) => sessions.sessionIdsFromUserIdSelector(state, uid).length > 0
    )
    .map((uid) => users.userSelector(state, uid)?.name);

  const displayChat =
    Object.keys(chat ?? {}).length > 0 ||
    feedback?.hasIssue === "minor" ||
    feedback?.hasIssue === "major";

  return (
    <Stack component={Paper} p={2} spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="end" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {date.status === "created" ? (
              <AccessTime />
            ) : (
              <IssueIcon hasIssue={feedback?.hasIssue} />
            )}
            <Typography variant="h4">{organization.name}</Typography>
          </Stack>
          <Typography color="text.secondary">
            {feedback === undefined
              ? "No feedback yet"
              : `Last update by ${
                  users.userSelector(state, feedback.uid)?.name
                }, ${new Date(feedback.date).toLocaleTimeString([], {
                  timeZone: "UTC",
                  timeStyle: "medium",
                })}`}
          </Typography>
        </Stack>
        {currentUser?.siteAdmin && (
          <Stack direction="row" spacing={1}>
            {connectedTakerNames.length > 0 ? (
              <>
                <Link color="success" />
                <Typography color="success.main">
                  {connectedTakerNames.join(", ")}
                </Typography>
              </>
            ) : (
              <>
                <LinkOff color="warning" />
                <Typography color="warning.main">No users connected</Typography>
              </>
            )}
          </Stack>
        )}
      </Stack>

      {isMember && date.status === "started" && (
        <IssueUpdater
          eid={eid}
          dateId={dateId}
          oid={oid}
          hasIssue={feedback?.hasIssue}
        />
      )}
      {displayChat && (
        <Chat eid={eid} dateId={dateId} oid={oid} uid={uid} chat={chat} />
      )}
    </Stack>
  );
};
