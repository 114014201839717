import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { organizations } from "store";

import { useStore } from "../../context/store";

export const OrganizationAdder: React.FC = () => {
  const { dispatch } = useStore();

  const [name, setName] = useState("");

  const [processing, setProcessing] = useState(false);

  const submittable = !processing && !!name;

  const handleSubmit = () => {
    if (!submittable) return;
    setProcessing(true);
    const action = organizations.createdProducer({ name });
    dispatch(action)
      .then(() => setName(""))
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Card component={Stack} justifyContent="space-between" variant="outlined">
      <CardContent>
        <Typography variant="h3" color="GrayText">
          New
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          type="text"
          variant="outlined"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          disabled={processing}
          onKeyDown={({ code }) => code === "Enter" && handleSubmit()}
        />
      </CardContent>
      <Stack component={CardActions} direction="row" justifyContent="flex-end">
        <Button disabled={!submittable} onClick={handleSubmit}>
          Create
        </Button>
      </Stack>
    </Card>
  );
};
