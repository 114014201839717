import React, { useMemo, useState } from "react";
import { DataGrid, GridEnrichedColDef, GridRowsProp } from "@mui/x-data-grid";
import { Button, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { users } from "store";

import { useStore } from "../../context/store";
import { Invite } from "../Invite";
import { memberships } from "./memberships";
import { actions } from "./actions";
import { online } from "./online";
import { admin } from "./admin";
import { email } from "./email";

export const Users: React.FC = () => {
  const { state, dispatch } = useStore();

  const rows: GridRowsProp = useMemo(
    () =>
      users
        .userIdsSelector(state)
        .map((uid) => ({ id: uid, ...users.userSelector(state, uid) })),
    [state]
  );

  const currentUser = users.currentUserSelector(state)!;

  const columns: Array<GridEnrichedColDef> = [
    online({ state }),
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: ({ id, value }) => (
        <Link component={RouterLink} to={String(id)}>
          {value}
        </Link>
      ),
    },
    email({ state }),
    { field: "phone", headerName: "Phone", width: 200 },
    memberships({ state }),
    admin({ state }),
  ];
  if (currentUser?.siteAdmin) {
    columns.push(actions({ field: "actions", state, dispatch }));
  }

  const Table = () => <DataGrid autoHeight {...{ columns, rows }} />;

  const [invitingUser, setInvitingUser] = useState(false);

  if (!currentUser.siteAdmin) {
    return <Table />;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Users</Typography>
      <Button variant="outlined" onClick={() => setInvitingUser(true)}>
        Invite user
      </Button>
      <Table />
      <Invite open={invitingUser} onClose={() => setInvitingUser(false)} />
    </Stack>
  );
};
