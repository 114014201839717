"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRestrictor = exports.reducer = exports.Action = void 0;
var utils_1 = require("../utils");
var sourceSet_1 = require("./sourceSet");
/** ACTIONS */
exports.Action = sourceSet_1.SourceSet;
/** REDUCER */
var reducer = function (_a) {
    var state = _a.state, action = _a.action;
    switch (action.name) {
        case "source-set":
            return (0, sourceSet_1.sourceSetReducer)({ state: state, action: action });
    }
};
exports.reducer = reducer;
/** ACCESS RESTRICTOR */
var accessRestrictor = function (_a) {
    var state = _a.state, target = _a.target;
    if (target.siteAdmin) {
        return state;
    }
    return Object.fromEntries((0, utils_1.definedEntries)(state)
        // remove urls for non-admins
        .map(function (_a) {
        var thumbnailId = _a[0], thumbnail = _a[1];
        return [thumbnailId, __assign(__assign({}, thumbnail), { url: null })];
    })
        // taker only
        .filter(function (_a) {
        var _ = _a[0], eid = _a[1].eid;
        return target.eids.includes(eid);
    }));
};
exports.accessRestrictor = accessRestrictor;
/** AGGREGATING */
__exportStar(require("./interface"), exports);
__exportStar(require("./sourceSet"), exports);
