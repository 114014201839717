import { useState } from "react";

import { organizations } from "store";

import { useStore } from "../../context/store";
import { SelectUser } from "../SelectUser";

export const UserAdder: React.FC<{
  oid: number;
  open: boolean;
  onClose: () => void;
}> = ({ oid, open, onClose }) => {
  const { state, dispatch } = useStore();

  const [processing, setProcessing] = useState(false);

  if (!open && !processing) {
    return <></>;
  }

  const handleSubmit = ({ uid }: { uid: number }) => {
    setProcessing(true);
    dispatch(organizations.addedUserProducer({ oid: oid!, uid }))
      .catch(alert)
      .then(() => {
        setProcessing(false);
        onClose();
      });
  };

  const organization = organizations.organizationSelector(state, oid)!;
  const title = `Add user to ${organization.name}`;

  return (
    <SelectUser
      open={open || processing}
      disabled={processing}
      title={title}
      filter={{ notOid: oid }}
      onCancel={() => !processing && onClose()}
      onSelect={handleSubmit}
    />
  );
};
