import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { organizations, users } from "store";

import { useStore } from "../../context/store";
import { visibleOnHover } from "../../utils/componentProps";
import { Member } from "./Member";
import { UserAdder } from "./UserAdder";

type Props = { oid: number };

export const Organization: React.FC<Props> = ({ oid }) => {
  const { dispatch, state } = useStore();

  const organization = organizations.organizationSelector(state, oid)!;

  const members = organizations
    .membersSelector(state, oid)!
    .map((member) => ({ ...users.userSelector(state, member.uid), ...member }));

  const otherAdmins = members.filter(({ orgAdmin }) => orgAdmin).length > 1;

  const [addingUser, setAddingUser] = useState(false);

  return (
    <Card
      component={Stack}
      justifyContent="space-between"
      sx={visibleOnHover(".MuiButton-root")}
    >
      <CardContent>
        <Typography variant="h3">{organization.name}</Typography>

        <List>
          {members.map((member) => (
            <Member {...{ ...member, oid, otherAdmins }} />
          ))}
        </List>
      </CardContent>
      <Stack
        component={CardActions}
        direction="row"
        justifyContent="space-between"
      >
        <Button onClick={() => setAddingUser(true)}>Add User</Button>
        <UserAdder
          {...{ oid, open: addingUser, onClose: () => setAddingUser(false) }}
        />
        <Button
          color="error"
          onClick={() =>
            dispatch(organizations.removedProducer({ oid })).catch(alert)
          }
        >
          Remove Organization
        </Button>
      </Stack>
    </Card>
  );
};
