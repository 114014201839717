import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";

import { emails, isEmail } from "store";

import { useStore } from "../../context/store";
import { dialogTextFieldProps } from "../../utils/componentProps";

type Props = {
  open: boolean;
  onClose: (args: { containsDraft: boolean }) => void;
};

export const Compose: React.FC<Props> = ({ open, onClose }) => {
  const { dispatch } = useStore();

  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  const empty = !(to || subject || text);
  const clear = () => {
    setTo("");
    setSubject("");
    setText("");
  };

  const [processing, setProcessing] = useState(false);
  const validEmail = useMemo(() => isEmail(to), [to]);
  const submittable = !processing && validEmail; // TODO: subject / text required?

  const handleClose = () => {
    if (!processing) {
      onClose({ containsDraft: Boolean(to || subject || text) });
    }
  };

  const handleSubmit = () => {
    if (!submittable) {
      return;
    }
    setProcessing(true);

    const action = emails.sentProducer({ to, subject, text });
    dispatch(action)
      .then(() => {
        handleClose();
        clear();
      })
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Dialog
      open={open || processing}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Compose Email</DialogTitle>

      <DialogContent>
        <TextField
          {...dialogTextFieldProps({ value: to, setValue: setTo })}
          autoFocus
          disabled={processing}
          label="To:"
          helperText="Single Email Address"
          error={to !== "" && !validEmail}
        />

        <TextField
          {...dialogTextFieldProps({ value: subject, setValue: setSubject })}
          disabled={processing}
          label="Subject:"
        />

        <TextField
          {...dialogTextFieldProps({ value: text, setValue: setText })}
          disabled={processing}
          multiline
          rows={7}
        />
      </DialogContent>

      <Stack
        component={DialogActions}
        direction="row"
        justifyContent="space-between"
      >
        <Button
          variant="text"
          color="error"
          disabled={empty || processing}
          onClick={clear}
        >
          Delete Draft
        </Button>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            variant="outlined"
            color="warning"
            disabled={processing}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            disabled={!submittable}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
