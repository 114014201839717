import { Close, Edit, Save } from "@mui/icons-material";
import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { assertDefined, broadcasts, users } from "store";

import { useStore } from "../../context/store";
import {
  gridContainerProps,
  gridItemProps,
  visibleOnHover,
} from "../../utils/componentProps";

type Props = { dateId: number; eid: number };

export const Broadcasts: React.FC<Props> = ({ dateId, eid }) => {
  const { state } = useStore();

  const { order, groups, fields, values } = state.broadcasts;

  const fieldValues = values[dateId]?.fields ?? {};

  const data = order
    .map((groupId) => ({
      groupId,
      ...assertDefined(groups[groupId]),
    }))
    .map(({ elements, ...rest }) => ({
      ...rest,
      fields: elements.map((fieldId) => ({
        fieldId,
        value: fieldValues[fieldId],
        ...assertDefined(fields[fieldId]),
      })),
    }));

  const [showAll, setShowAll] = useState(false);

  const currentUser = users.currentUserSelector(state);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">Broadcasts</Typography>

        {currentUser?.siteAdmin && (
          <FormControlLabel
            control={
              <Switch
                checked={showAll}
                onChange={({ target: { checked } }) => setShowAll(checked)}
              />
            }
            label="Show All"
          />
        )}
      </Stack>

      <Grid {...gridContainerProps({ spacing: 2 })}>
        {data.map(({ groupId, title, fields }) => (
          <Grid key={groupId} {...gridItemProps({ firstBreakpoint: "md" })}>
            <Card>
              <CardContent>
                <Typography variant="h4">{title}</Typography>
                {fields
                  .filter(
                    ({ required, value }) =>
                      value !== undefined || required || showAll
                  )
                  .map(({ fieldId, required, name, value, allowed }) => (
                    <Stack direction="row" spacing={1}>
                      <Typography fontWeight={required ? "bold" : undefined}>
                        {name}:
                      </Typography>
                      <FieldValue
                        {...{
                          eid,
                          editing: showAll,
                          allowed,
                          fieldId,
                          dateId,
                          required,
                          value,
                        }}
                      />
                    </Stack>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

type FieldValueProps = {
  eid: number;
  dateId: number;
  fieldId: number;
  allowed: readonly string[];
  required: boolean;
  value: string | undefined;
};

const FieldValue: React.FC<FieldValueProps> = ({
  eid,
  dateId,
  fieldId,
  allowed,
  required,
  ...props
}) => {
  const { state, dispatch } = useStore();

  const currentUser = users.currentUserSelector(state);

  const [editing, setEditing] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [value, setValue] = useState("");
  useEffect(() => setValue(props.value ?? ""), [props.value]);

  if (!editing) {
    return (
      <Stack
        direction="row"
        spacing={1}
        flexGrow={1}
        sx={visibleOnHover(".MuiIconButton-root")}
      >
        <Typography
          {...(props.value === undefined
            ? required
              ? { color: "error.main" }
              : undefined
            : { fontStyle: "monospace" })}
        >
          {props.value ?? (required ? "REQUIRED" : "NOT DEFINED")}
        </Typography>
        {currentUser?.siteAdmin && (
          <IconButton
            size="small"
            edge="end"
            sx={{ padding: 0 }}
            onClick={() => setEditing(true)}
          >
            <Edit />
          </IconButton>
        )}
      </Stack>
    );
  }

  const acceptAny = allowed.length === 0;
  const valid = acceptAny
    ? value.trim() !== ""
    : allowed.includes(value.trim());
  const submittable = !processing && valid;

  const handleClose = () => {
    if (processing) {
      return;
    }
    setValue(props.value ?? "");
    setEditing(false);
  };

  const handleSave = () => {
    if (!submittable) {
      return;
    }
    setProcessing(true);
    const action = broadcasts.valueUpdatedProducer({
      value,
      eid,
      dateId,
      fieldId,
    });
    dispatch(action)
      .then(() => setEditing(false))
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexGrow={1}
      alignItems="center"
    >
      {acceptAny ? (
        <TextField
          size="small"
          value={value}
          onChange={({ target: { value } }) => setValue(value)}
          error={!valid}
          disabled={processing}
        />
      ) : (
        <Select
          value={value}
          fullWidth
          onChange={({ target: { value } }) => setValue(value)}
          disabled={processing}
        >
          {allowed.map((value) => (
            <MenuItem value={value}>{value}</MenuItem>
          ))}
        </Select>
      )}

      <Stack>
        <IconButton
          size="small"
          edge="end"
          sx={{ padding: 0 }}
          onClick={handleClose}
          disabled={processing}
        >
          <Close />
        </IconButton>
        <IconButton
          size="small"
          edge="end"
          sx={{ padding: 0 }}
          onClick={handleSave}
          disabled={!submittable}
        >
          <Save />
        </IconButton>
      </Stack>
    </Stack>
  );
};
