"use strict";
/** META */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseClientRef = void 0;
var zod_1 = require("zod");
/** CLIENT REF */
var ClientRef = zod_1.z.object({ clientRef: zod_1.z.string().optional() });
var parseClientRef = function (data) {
    return ClientRef.parse(data);
};
exports.parseClientRef = parseClientRef;
