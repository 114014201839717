import { Shield } from "@mui/icons-material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { State, users } from "store";

type Props = {
  state: State;
};

export const admin = ({ state }: Props): GridColDef => ({
  field: "admin",
  headerName: "",
  valueGetter: getter(state),
  renderCell: render,
  minWidth: 40,
  width: 40,
});

type Value = { siteAdmin: boolean };

const getter =
  (state: State) =>
  (params: GridValueGetterParams): Value => {
    const uid = Number(params.id);
    return users.userSelector(state, uid)!;
  };

const render = (params: GridRenderCellParams<Value>) =>
  params.value.siteAdmin ? (
    <Shield color="action" titleAccess="site admin" />
  ) : (
    <></>
  );
