import { AddModerator, Delete, RemoveModerator } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridRowParams,
} from "@mui/x-data-grid";

import { State, users } from "store";
import { Dispatch } from "../../context/store";

type Props = {
  field: string;
  state: State;
  dispatch: Dispatch;
};

export const actions = (props: Props): GridActionsColDef => ({
  field: "actions",
  type: "actions",
  getActions: render(props),
  minWidth: 25,
  width: 25,
});

const render =
  ({ dispatch, state }: Props) =>
  (params: GridRowParams) => {
    const uid = Number(params.id);
    const { siteAdmin } = users.userSelector(state, uid)!;
    const currentUser = users.currentUserSelector(state);

    return [
      <GridActionsCellItem
        icon={siteAdmin ? <RemoveModerator /> : <AddModerator />}
        label={`${siteAdmin ? "Remove" : "Make"} Site Admin`}
        showInMenu
        disabled={currentUser?.uid === uid}
        onClick={() => {
          const producer = siteAdmin
            ? users.removedAdminProducer
            : users.madeAdminProducer;
          const action = producer({ uid });
          dispatch(action).catch(alert);
        }}
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label="Remove User"
        showInMenu
        disabled={currentUser?.uid === uid}
        onClick={() => dispatch(users.removedProducer({ uid })).catch(alert)}
      />,
    ];
  };
