"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRestrictor = exports.reducer = exports.Action = void 0;
var zod_1 = require("zod");
var utils_1 = require("../utils");
var fieldAdded_1 = require("./fieldAdded");
var fieldUpdated_1 = require("./fieldUpdated");
var groupAdded_1 = require("./groupAdded");
var valueUpdated_1 = require("./valueUpdated");
/** ACTIONS */
exports.Action = zod_1.z.union([
    fieldAdded_1.FieldAdded,
    fieldUpdated_1.FieldUpdated,
    groupAdded_1.GroupAdded,
    valueUpdated_1.ValueUpdated,
]);
/** REDUCER */
var reducer = function (_a) {
    var state = _a.state, action = _a.action;
    switch (action.name) {
        case "field-added":
            return (0, fieldAdded_1.fieldAddedReducer)({ state: state, action: action });
        case "field-updated":
            return (0, fieldUpdated_1.fieldUpdatedReducer)({ state: state, action: action });
        case "group-added":
            return (0, groupAdded_1.groupAddedReducer)({ state: state, action: action });
        case "value-updated":
            return (0, valueUpdated_1.valueUpdatedReducer)({ state: state, action: action });
    }
};
exports.reducer = reducer;
/** ACCESS RESTRICTOR */
var accessRestrictor = function (_a) {
    var state = _a.state, target = _a.target;
    if (target.siteAdmin) {
        return state;
    }
    var values = state.values, stateRest = __rest(state, ["values"]);
    return __assign(__assign({}, stateRest), { values: Object.fromEntries((0, utils_1.definedEntries)(values).filter(function (_a) {
            var _ = _a[0], eid = _a[1].eid;
            return target.eids.includes(eid);
        })) });
};
exports.accessRestrictor = accessRestrictor;
/** AGGREGATING */
__exportStar(require("./fieldAdded"), exports);
__exportStar(require("./fieldUpdated"), exports);
__exportStar(require("./groupAdded"), exports);
__exportStar(require("./valueUpdated"), exports);
