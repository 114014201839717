"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseResponse = exports.parseRequest = void 0;
var zod_1 = require("zod");
var Request = zod_1.z.object({
    thumbnailId: zod_1.z.number(),
});
var parseRequest = function (data) { return Request.parse(data); };
exports.parseRequest = parseRequest;
var Response = zod_1.z.union([
    zod_1.z.object({ thumbnailId: zod_1.z.number(), data: zod_1.z.string() }),
    zod_1.z.object({ thumbnailId: zod_1.z.any(), error: zod_1.z.string() }),
]);
var parseResponse = function (data) { return Response.parse(data); };
exports.parseResponse = parseResponse;
