"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRestrictor = exports.memberSelector = exports.membersSelector = exports.userIdsSelector = exports.organizationSelector = exports.organizationIdsSelector = exports.organizationsSelector = exports.reducer = exports.Action = void 0;
var reselect_1 = require("reselect");
var zod_1 = require("zod");
var utils_1 = require("../utils");
var user_1 = require("./user");
var admin_1 = require("./admin");
var created_1 = require("./created");
var removed_1 = require("./removed");
/** ACTIONS */
exports.Action = zod_1.z.union([
    user_1.AddedUser,
    user_1.RemovedUser,
    created_1.Created,
    removed_1.Removed,
    admin_1.MadeAdmin,
    admin_1.RemovedAdmin,
]);
/** REDUCER */
var reducer = function (_a) {
    var state = _a.state, action = _a.action;
    switch (action.name) {
        case "added-user":
            return (0, user_1.addedUserReducer)({ state: state, action: action });
        case "removed-user":
            return (0, user_1.removedUserReducer)({ state: state, action: action });
        case "created":
            return (0, created_1.createdReducer)({ state: state, action: action });
        case "removed":
            return (0, removed_1.removedReducer)({ state: state, action: action });
        case "made-admin":
            return (0, admin_1.madeAdminReducer)({ state: state, action: action });
        case "removed-admin":
            return (0, admin_1.removedAdminReducer)({ state: state, action: action });
    }
};
exports.reducer = reducer;
/** SELECTOR */
var organizationsSelector = function (state) { return state.organizations; };
exports.organizationsSelector = organizationsSelector;
exports.organizationIdsSelector = (0, reselect_1.createSelector)(exports.organizationsSelector, function (organizations) { return Object.keys(organizations).map(Number); });
var organizationSelector = function (state, oid) {
    return state.organizations[oid];
};
exports.organizationSelector = organizationSelector;
exports.userIdsSelector = (0, reselect_1.createSelector)(exports.organizationSelector, function (organization) { return organization && Object.keys(organization.users).map(Number); });
exports.membersSelector = (0, reselect_1.createSelector)(exports.organizationSelector, function (organization) {
    return organization &&
        (0, utils_1.definedEntries)(organization.users).map(function (_a) {
            var uid = _a[0], value = _a[1];
            return (__assign({ uid: uid }, value));
        });
});
exports.memberSelector = (0, reselect_1.createSelector)(exports.organizationSelector, function (_state, _oid, uid) { return uid; }, function (organization, uid) { return organization === null || organization === void 0 ? void 0 : organization.users[uid]; });
/** ACCESS RESTRICTOR */
var accessRestrictor = function (_a) {
    var state = _a.state, target = _a.target;
    if (target.siteAdmin) {
        return state;
    }
    return Object.fromEntries((0, utils_1.definedEntries)(state).filter(function (_a) {
        var oid = _a[0];
        return target.oids.includes(oid);
    }));
};
exports.accessRestrictor = accessRestrictor;
/** AGGREGATING */
__exportStar(require("./user"), exports);
__exportStar(require("./admin"), exports);
__exportStar(require("./created"), exports);
__exportStar(require("./removed"), exports);
