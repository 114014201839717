"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRestrictor = exports.reducer = exports.Action = void 0;
var sent_1 = require("./sent");
/** ACTIONS */
exports.Action = sent_1.Sent;
/** REDUCER */
var reducer = function (_a) {
    var action = _a.action, state = _a.state;
    switch (action.name) {
        case "sent":
            return (0, sent_1.sentReducer)({ action: action, state: state });
    }
};
exports.reducer = reducer;
/** ACCESS RESTRICTOR */
var accessRestrictor = function (_a) {
    var state = _a.state, target = _a.target;
    return target.siteAdmin ? state : {};
};
exports.accessRestrictor = accessRestrictor;
/** AGGREGATION */
__exportStar(require("./sent"), exports);
