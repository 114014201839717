import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { definedKeys } from "store";

import { useStore } from "../../context/store";
import { Compose } from "./compose";
import { Message } from "./Message";

export const Emails: React.FC = () => {
  const { state } = useStore();

  const [composing, setComposing] = useState(false);
  const [containsDraft, setContainsDraft] = useState(false);

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h2">Emails</Typography>
        <Button variant="outlined" onClick={() => setComposing(true)}>
          {containsDraft ? "Continue Draft" : "Compose New"}
        </Button>
        {definedKeys(state.emails)
          .reverse()
          .map((emailId) => (
            <Message {...{ emailId }} />
          ))}
      </Stack>

      <Compose
        open={composing}
        onClose={({ containsDraft }) => {
          setContainsDraft(containsDraft);
          setComposing(false);
        }}
      />
    </>
  );
};
