import { Button, Link } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { State, users } from "store";

type Props = {
  state: State;
};

export const email = ({ state }: Props): GridColDef => ({
  field: "email",
  headerName: "Email / Invitation",
  valueGetter: getter(state),
  renderCell: render,
  width: 200,
});

type Value = { email: string } | { code: string | null };

const getter =
  (state: State) =>
  (params: GridValueGetterParams): Value => {
    const uid = Number(params.id);
    return users.userSelector(state, uid)!;
  };

const render = (params: GridRenderCellParams<Value>) => {
  const { value } = params;

  if ("email" in value) {
    return <Link href={`mailto:${value.email}`}>{value.email}</Link>;
  }

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() =>
        navigator.clipboard.writeText(
          `${window.location.origin}/invite/${value.code}`
        )
      }
    >
      Copy Invite Link
    </Button>
  );
};
