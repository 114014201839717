import { Delete } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { events, definedValues, users } from "store";

import { useStore } from "../../context/store";
import { visibleOnHover } from "../../utils/componentProps";
import { formatWithoutSeconds } from "../../utils/dates";
import { Takers } from "../takers";

const NextDateLine = (props: {
  nextDate:
    | { times: { start: string }; status: "created" | "started" | "stopped" }
    | undefined;
}) => {
  const { nextDate } = props;
  if (nextDate === undefined) return <Typography>No upcoming dates</Typography>;

  return (
    <Typography>
      {nextDate.status === "created" ? "Next: " : "Current: "}
      {formatWithoutSeconds(nextDate.times.start)}
    </Typography>
  );
};

export const Previewer: React.FC<{
  eid: number;
}> = ({ eid }) => {
  const { dispatch, state } = useStore();

  const event = events.eventSelector(state, { eid });

  const currentUser = users.currentUserSelector(state);

  const upcomingDates = definedValues(event.dates)
    .filter((event) => event.status !== "stopped")
    .sort((a, b) => +new Date(a.times.start) - +new Date(b.times.start));
  const nextDate = upcomingDates.length > 0 ? upcomingDates[0] : undefined; // fixes typings

  return (
    <Card key={eid} sx={visibleOnHover(".remove-event")}>
      <CardContent component={Stack} spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Link
            variant="h3"
            color="inherit"
            underline="none"
            component={RouterLink}
            to={`${eid}`}
          >
            {event.name}
          </Link>
          <Typography color="text.secondary">
            {Object.keys(event.dates).length || "No"} date(s)
          </Typography>
        </Stack>
        {(Object.keys(event.dates).length && (
          <NextDateLine nextDate={nextDate} />
        )) ||
          undefined}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Takers {...{ eid }} />
          {currentUser?.siteAdmin && (
            <IconButton
              className="remove-event"
              aria-label="remove"
              onClick={() => dispatch(events.removedProducer({ eid }))}
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
