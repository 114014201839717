import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";

import { useStore } from "../../context/store";
import { FieldEditor } from "./FieldEditor";

type Props = {
  fieldId: number;
};

export const Field: React.FC<Props> = ({ fieldId }) => {
  const { state } = useStore();

  const { required, allowed, name } = state.broadcasts.fields[fieldId]!;

  const primaryTextProps = {
    primary: name,
    primaryTypographyProps: { fontWeight: required ? "bold" : undefined },
  };

  const secondaryTextProps =
    allowed.length === 0
      ? { secondary: "any text" }
      : {
          secondary: allowed.join(", "),
          secondaryTypographyProps: { fontFamily: "monospace" },
        };

  const [editing, setEditing] = useState(false);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          key={fieldId}
          disabled={editing}
          onClick={() => setEditing(true)}
        >
          <ListItemText {...primaryTextProps} {...secondaryTextProps} />
        </ListItemButton>
      </ListItem>
      {editing && (
        <FieldEditor {...{ fieldId, onClose: () => setEditing(false) }} />
      )}
    </>
  );
};
