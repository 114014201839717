import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { isEmail, users } from "store";

import { useStore } from "../../context/store";
import { dialogTextFieldProps } from "../../utils/componentProps";

export const Profile: React.FC = () => {
  const { state } = useStore();

  const params = useParams();
  const userId = parseInt(params.userId ?? "");

  const user = state.users[userId];
  if (user === undefined) {
    return <Navigate to="/" />;
  }

  return <Editor {...{ userId, user }} />;
};

const Editor: React.FC<{ userId: number; user: users.User }> = ({
  userId,
  user,
}) => {
  const { dispatch, state } = useStore();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState<string | null>(null); // null when preliminary

  const initialize = () => {
    setName(user.name);
    setPhone(user.phone ?? "");
    setEmail(user.status === "registered" ? user.email : null);
  };

  useEffect(initialize, [user]);

  const emailInvalid = useMemo(
    () => email !== null && !isEmail(email),
    [email]
  );
  const changed =
    name !== user.name ||
    phone !== (user.phone ?? "") ||
    (user.status === "registered" && user.email !== email);
  const submittable = changed && name && !emailInvalid;

  const isCurrent = users.currentUserSelector(state)?.uid === userId;

  const [processing, setProcessing] = useState(false);
  const handleSave = () => {
    if (!submittable || processing) {
      return;
    }
    setProcessing(true);
    const action = users.updatedProducer({
      userId,
      name: name !== user.name ? name : undefined,
      phone: phone !== (user.phone ?? "") ? phone : undefined,
      email:
        user.status === "registered" && user.email !== email && email !== null
          ? email
          : undefined,
    });
    dispatch(action)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Stack component={Box} spacing={2} maxWidth="sm">
      <Typography variant="h2">
        {isCurrent ? "Your Profile" : `Profile of ${user.name}`}
      </Typography>

      <TextField
        {...dialogTextFieldProps({ setValue: setName, value: name })}
        label="Name"
        disabled={processing}
        error={!name}
        helperText={!name ? "Required" : " "}
      />

      <TextField
        {...dialogTextFieldProps({ setValue: setPhone, value: phone })}
        label="Phone"
        disabled={processing}
        helperText=" "
      />

      <TextField
        {...dialogTextFieldProps({
          setValue: setEmail,
          value: email ?? "not registered",
        })}
        label="Email"
        disabled={email === null || processing}
        error={emailInvalid}
        helperText={
          email === null
            ? "Set during registration"
            : emailInvalid
            ? "Not valid"
            : " "
        }
      />

      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button
          variant="outlined"
          disabled={!changed || processing}
          onClick={initialize}
          color="warning"
        >
          Discard
        </Button>
        <Button
          variant="contained"
          disabled={!submittable || processing}
          onClick={handleSave}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
