"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.definedKeys = exports.definedEntries = exports.definedValues = exports.assertDefined = exports.isDefined = exports.update = exports.isEmail = void 0;
var zod_1 = require("zod");
var isEmail = function (input) {
    return zod_1.z.string().email().safeParse(input).success;
};
exports.isEmail = isEmail;
var update = function (target, source) {
    var keys = Array.from(new Set(__spreadArray(__spreadArray([], Object.keys(target), true), Object.keys(source), true)));
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        if (!(key in target)) {
            target[key] = source[key];
        }
        else if (!(key in source)) {
            delete target[key];
        }
        else if (typeof target[key] === "object" &&
            target[key] !== null &&
            typeof source[key] === "object" &&
            source[key] !== null) {
            (0, exports.update)(target[key], source[key]);
        }
        else if (target[key] !== source[key]) {
            target[key] = source[key];
        }
    }
};
exports.update = update;
var isDefined = function (value) {
    return value !== undefined;
};
exports.isDefined = isDefined;
var assertDefined = function (value) {
    if (!(0, exports.isDefined)(value)) {
        throw Error("Not defined");
    }
    return value;
};
exports.assertDefined = assertDefined;
var definedValues = function (object) {
    return Object.values(object).filter(exports.isDefined);
};
exports.definedValues = definedValues;
var definedEntries = function (object) {
    return Object.entries(object)
        .filter(function (_a) {
        var _ = _a[0], value = _a[1];
        return (0, exports.isDefined)(value);
    })
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return [Number(key), value];
    });
};
exports.definedEntries = definedEntries;
var definedKeys = function (object) {
    // using Object.keys would require checking whether the value is defined manually
    return (0, exports.definedEntries)(object).map(function (_a) {
        var key = _a[0];
        return key;
    });
};
exports.definedKeys = definedKeys;
