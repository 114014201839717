import React, { createContext, useContext, useMemo, useState } from "react";
import {
  createTheme,
  PaletteMode,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from "@mui/material";

import { HOC } from "../utils/hoc";

const PALETTE: { [mode in PaletteMode]: ThemeOptions["palette"] } = {
  light: {
    mode: "light",
    primary: {
      main: "#18938E",
    },
    secondary: {
      main: "#056878",
    },
  },
  dark: {
    mode: "dark",
    primary: {
      main: "#18938E",
    },
    secondary: {
      main: "#056878",
    },
  },
};

const typography: ThemeOptions["typography"] = (() => {
  const defaultT = createTheme().typography;
  return {
    h1: defaultT.h4, // bar title "Rights Booking" only
    h2: defaultT.h3,
    h3: defaultT.h4,
    h4: defaultT.h5,
    h5: defaultT.h6,
    h6: defaultT.h6, // used by DialogTitle
  };
})();

type SetPaletteMode = { setPaletteMode: (paletteMode: PaletteMode) => void };
const SetPaletteModeContext = createContext<SetPaletteMode>({
  setPaletteMode: () => {
    throw Error("Set palette mode context uninitialized");
  },
});
export const useSetPaletteMode = () => useContext(SetPaletteModeContext);

export const ThemeProvider: HOC = ({ children }) => {
  const [paletteMode, setPaletteMode] = useState<PaletteMode>(
    useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light"
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: PALETTE[paletteMode],
        typography,
      }),
    [paletteMode]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <SetPaletteModeContext.Provider value={{ setPaletteMode }}>
        {children}
      </SetPaletteModeContext.Provider>
    </MuiThemeProvider>
  );
};
