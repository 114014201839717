import { KeyboardReturn } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { events } from "store";

import { useStore } from "../../context/store";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const Adder: React.FC<Props> = (props) => {
  const { dispatch } = useStore();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [name, setName] = useState("");

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    } else {
      if (!processing) {
        setAnimating(true);
        setOpen(false);
        setTimeout(
          () => setAnimating(false),
          theme.transitions.duration.leavingScreen
        );
      }
    }
  }, [props.open, processing, setAnimating, theme]);

  if (!open && !animating) return <></>;

  // TODO: code above should become a utility HOC

  const handleClose = () => {
    if (!processing) {
      props.onClose();
    }
  };

  const submittable = !!name;

  const handleSubmit = () => {
    if (submittable) {
      setProcessing(true);
      const action = events.createdProducer({ name });
      dispatch(action)
        .then(props.onClose)
        .catch(alert)
        .then(() => setProcessing(false));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Add Event</DialogTitle>

      <DialogContent>
        <TextField
          label="Name"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          autoFocus
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          disabled={processing}
          onKeyDown={({ code }: { code: string }) =>
            code === "Enter" && handleSubmit()
          }
          InputProps={{
            endAdornment: submittable && (
              <InputAdornment position="end">
                <KeyboardReturn />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!submittable || processing} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
