import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { uploads } from "store";
import { useStore } from "../context/store";

type Props = {
  open: boolean;
  onClose: () => void;
  eid: number;
  dateId: number | null;
};

export const Uploader: React.FC<Props> = ({ eid, dateId, ...props }) => {
  const { dispatch, getState } = useStore();

  const [processing, setProcessing] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState("");

  // this dialog needs to be reusable
  useEffect(() => {
    // don't overwrite if still processing
    if (!props.open || processing) {
      return;
    }
    setFile(null);
    setDescription("");
  }, [processing, props.open]);

  const submittable = file !== null;

  const handleClose = () => !processing && props.onClose();

  const handleSubmit = () => {
    setProcessing(true);
    const action = uploads.uploadedProducer({
      name: file!.name,
      eid,
      dateId,
      description,
    });
    dispatch(action)
      .then(handleUpload)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const handleUpload = () => {
    const state = getState(); // FIXME state from context is not updated after dispatch
    const url = uploads.uploadUrlSelector(state, { eid, dateId });
    if (url === undefined) {
      throw Error("No upload url available");
    }

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url);
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        props.onClose();
      } else {
        throw Error(xhr.statusText);
      }
    };
    xhr.onerror = (error) => {
      throw Error(error + xhr.statusText);
    };
    xhr.send(file);
  };

  return (
    <Dialog
      open={props.open || processing}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Upload File</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          label="Details"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
          disabled={processing}
          multiline
          rows={3}
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
        />

        <input
          type="file"
          accept="pdf"
          multiple={false}
          onChange={({ currentTarget: { files } }) =>
            setFile(files?.item(0) ?? null)
          }
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!submittable || processing} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
