import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { KeyboardReturn, PersonSearch } from "@mui/icons-material";
import { useMemo, useState } from "react";

import { useStore } from "../context/store";
import { organizations, users } from "store";

type SelectUserProps = {
  open: boolean;
  disabled: boolean;
  title: string;
  onCancel: () => void;
  onSelect: ({ uid }: { uid: number }) => void;
  filter?: { notOid?: number };
};

export const SelectUser: React.FC<SelectUserProps> = ({
  open,
  disabled,
  title,
  onCancel,
  onSelect,
  filter = {},
}) => {
  const { state } = useStore();

  const [search, setSearch] = useState("");

  const uids = users.userIdsSelector(state);
  const usersWithUids = useMemo(() => {
    let res = uids.flatMap((uid) => {
      const user = users.userSelector(state, uid);
      return user
        ? [
            {
              uid,
              user,
            },
          ]
        : [];
    });
    if (filter.notOid) {
      const org = organizations.organizationSelector(state, filter.notOid);
      res = res.filter(({ uid }) => !org || !(uid in org.users));
    }
    return res;
  }, [uids, filter, state]);
  const searchResults = useMemo(
    () =>
      usersWithUids.filter(
        ({ user }) =>
          search === "" ||
          user.name.includes(search) ||
          (user.phone && user.phone.includes(search)) ||
          (user.status === "registered" && user.email.includes(search))
      ),
    [usersWithUids, search]
  );

  const ResultsList = () => {
    if (searchResults.length === 0) {
      return <p>No results</p>;
    }
    return (
      <List>
        {searchResults.map(({ uid, user }) => {
          const secondary =
            user.status === "registered" ? user.email : "invited";
          return (
            <ListItemButton
              disabled={disabled}
              key={uid}
              onClick={() => onSelect({ uid })}
            >
              <ListItemText primary={user.name} secondary={secondary} />
            </ListItemButton>
          );
        })}
      </List>
    );
  };

  const singleResult = searchResults.length === 1 && searchResults[0];

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <TextField
          placeholder="Search..."
          fullWidth
          autoFocus
          disabled={disabled}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          onKeyDown={({ code }) =>
            code === "Enter" && singleResult && onSelect(singleResult)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonSearch />
              </InputAdornment>
            ),
            endAdornment: singleResult && (
              <InputAdornment position="end">
                <KeyboardReturn />
              </InputAdornment>
            ),
          }}
        />
        <ResultsList />
      </DialogContent>
    </Dialog>
  );
};
