import { Stack, Typography } from "@mui/material";
import { useStore } from "../../context/store";
import { Group } from "./Group";
import { GroupAdder } from "./GroupAdder";

export const BroadcastFields: React.FC = () => {
  const { state } = useStore();

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Broadcast Fields</Typography>

      {state.broadcasts.order.map((groupId) => (
        <Group key={groupId} {...{ groupId }} />
      ))}

      <GroupAdder />
    </Stack>
  );
};
