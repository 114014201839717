import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { definedKeys, events, isDefined, issues, users } from "store";

import { useStore } from "../../context/store";

type Props = { eid: number; dateId: number };

export const Issues: React.FC<Props> = (props) => {
  const { state, dispatch } = useStore();

  const currentUser = users.currentUserSelector(state);
  const date = events.dateSelector(state, props);
  const dateIssues = definedKeys(date.issues)
    .filter((issueId) => isDefined(state.issues[issueId]))
    .map((issueId) => ({ issueId, ...state.issues[issueId]! }));

  const [processing, setProcessing] = useState(false);
  const [description, setDescription] = useState("");

  const handleAdd = (severity: "minor" | "major") => {
    if (processing) return;
    setProcessing(true);
    const action = issues.addedProducer({
      ...props,
      description,
      severity,
    });
    dispatch(action)
      .then(() => setDescription(""))
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const handleSolve = (issueId: number) => {
    if (processing) return;
    setProcessing(true);
    const action = issues.solvedProducer({ ...props, issueId });
    dispatch(action)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h3">
        {dateIssues.length === 0 ? "No Issues Reported" : "Reported Issues"}
      </Typography>

      {dateIssues.map(({ severity, description, status, issueId }) => (
        <Card component={Box} key={issueId} p={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography color="text.secondary" gutterBottom>
              {severity} issue
            </Typography>
            {currentUser?.siteAdmin && status === "pending" && (
              <Button onClick={() => handleSolve(issueId)}>Solve</Button>
            )}
          </Stack>
          <Typography
            sx={{
              textDecoration: status === "solved" ? "line-through" : "none",
            }}
          >
            {description}
          </Typography>
        </Card>
      ))}

      {currentUser?.siteAdmin && date.status !== "stopped" && (
        <>
          <TextField
            fullWidth
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
          />
          <Stack direction="row" spacing={2}>
            <Button
              color="warning"
              variant="outlined"
              disabled={!description || processing}
              onClick={() => handleAdd("minor")}
            >
              Add Minor (Private) Issue
            </Button>
            <Button
              color="error"
              variant="contained"
              disabled={!description || processing}
              onClick={() => handleAdd("major")}
            >
              Add Major (Public) Issue
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};
