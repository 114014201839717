"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRestrictor = exports.reducer = exports.Action = void 0;
var utils_1 = require("../utils");
var updated_1 = require("./updated");
/** ACTIONS */
exports.Action = updated_1.Updated;
/** REDUCER */
var reducer = function (_a) {
    var state = _a.state, action = _a.action;
    switch (action.name) {
        case "updated":
            return (0, updated_1.updatedReducer)({ state: state, action: action });
    }
};
exports.reducer = reducer;
/** ACCESS RESTRICTOR */
var accessRestrictor = function (_a) {
    var state = _a.state, target = _a.target;
    if (target.siteAdmin) {
        return state;
    }
    return Object.fromEntries((0, utils_1.definedEntries)(state).filter(function (_a) {
        var _ = _a[0], oid = _a[1].oid;
        return target.oids.includes(oid);
    }));
};
exports.accessRestrictor = accessRestrictor;
/** AGGREGATING */
__exportStar(require("./updated"), exports);
