import { Grid, Stack, Typography } from "@mui/material";
import { organizations } from "store";
import { useStore } from "../../context/store";
import { gridContainerProps, gridItemProps } from "../../utils/componentProps";
import { Organization } from "./Organization";
import { OrganizationAdder } from "./OrganizationAdder";

export const Organizations: React.FC = () => {
  const { state } = useStore();

  const oids = organizations.organizationIdsSelector(state);

  const firstBreakpoint = "md";

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Organizations</Typography>

      <Grid {...gridContainerProps({ spacing: 2 })}>
        {oids.map((oid) => (
          <Grid key={oid} {...gridItemProps({ firstBreakpoint })}>
            <Organization oid={oid} />
          </Grid>
        ))}
        <Grid {...gridItemProps({ firstBreakpoint })}>
          <OrganizationAdder />
        </Grid>
      </Grid>
    </Stack>
  );
};
