export type Defer<T> = {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
};

/**
 * Externalizes resolve and reject calls of promises (breaks throw safety)
 *
 * See https://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
 * and https://stackoverflow.com/questions/26150232/
 */
export const defer = <T>(): Defer<T> => {
  let res: Defer<T>["resolve"] | undefined, rej: Defer<T>["reject"] | undefined;
  const promise = new Promise<T>((_res, _rej) => {
    res = _res;
    rej = _rej;
  });
  return {
    promise,
    resolve: res!,
    reject: rej!,
  };
};
