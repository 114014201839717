import { Link, LinkOff } from "@mui/icons-material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { sessions, State } from "store";

type Props = {
  state: State;
};

export const online = ({ state }: Props): GridColDef => ({
  field: "online",
  headerName: "",
  valueGetter: getter(state),
  renderCell: render,
  minWidth: 40,
  width: 40,
});

type Value = boolean;

const getter =
  (state: State) =>
  (params: GridValueGetterParams): Value => {
    const uid = Number(params.id);
    return sessions.sessionIdsFromUserIdSelector(state, uid).length > 0;
  };

const render = (params: GridRenderCellParams<Value>) =>
  params.value ? (
    <Link titleAccess="online" />
  ) : (
    <LinkOff titleAccess="offline" color="disabled" />
  );
