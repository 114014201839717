import { DateTimePicker } from "@mui/lab";
import { TextField, TextFieldProps } from "@mui/material";

type Props = {
  time: string;
  onTimeChange: (time: string) => void;
  label: string;
  textFieldProps: Partial<TextFieldProps>;
};

const toTz = (time: string): string => {
  const date = new Date(time);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date.toUTCString();
};

export const TimePicker: React.FC<Props> = ({
  time,
  onTimeChange,
  label,
  textFieldProps,
}) => (
  <DateTimePicker
    label={label}
    value={toTz(time)}
    onChange={(_date) => {
      const date = _date as unknown as Date;
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      onTimeChange(date.toUTCString());
    }}
    renderInput={(params) => (
      <TextField type="text" {...textFieldProps} {...params} />
    )}
  />
);
