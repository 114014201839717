import { PersonRemove, AdminPanelSettings } from "@mui/icons-material";
import { ListItem, IconButton, ListItemText } from "@mui/material";

import { organizations, users } from "store";

import { useStore } from "../../context/store";
import { visibleOnHover } from "../../utils/componentProps";

type Props = {
  oid: number;
  uid: number;
};

export const Member: React.FC<Props> = ({ oid, uid }) => {
  const { dispatch, state } = useStore();

  const currentUser = users.currentUserSelector(state)!;
  const member = {
    uid,
    ...users.userSelector(state, uid)!,
    ...organizations.memberSelector(state, oid, uid)!,
  };

  const handleAdminClick = () => {
    const producer = member.orgAdmin
      ? organizations.removedAdminProducer
      : organizations.madeAdminProducer;
    const action = producer({ oid, uid });
    dispatch(action).catch(alert);
  };

  const handleRemoveClick = () => {
    const action = organizations.removedUserProducer({ oid, uid });
    dispatch(action).catch(alert);
  };

  const canEdit =
    organizations.memberSelector(state, oid, currentUser.uid)?.orgAdmin ||
    currentUser.siteAdmin;

  const otherAdmins =
    organizations
      .membersSelector(state, oid)!
      .filter(({ orgAdmin }) => orgAdmin)
      .filter(({ uid }) => uid !== member.uid).length > 0;

  return (
    <ListItem
      key={uid}
      disablePadding
      sx={visibleOnHover(".remove-user, .make-admin")}
      secondaryAction={
        canEdit &&
        !member.orgAdmin && (
          <IconButton
            className="remove-user"
            edge="end"
            onClick={handleRemoveClick}
          >
            <PersonRemove />
          </IconButton>
        )
      }
    >
      <IconButton
        className="make-admin"
        size="small"
        onClick={handleAdminClick}
        disabled={!canEdit || !otherAdmins}
      >
        <AdminPanelSettings
          {...(member.orgAdmin
            ? {
                color: "action",
                visibility: "visible" /* overwrites disabled above */,
              }
            : { color: "disabled" })}
        />
      </IconButton>
      <ListItemText primary={member.name} />
    </ListItem>
  );
};
