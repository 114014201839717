import {
  Chat,
  FileDownload,
  FileDownloadOff,
  PersonalVideo,
  Report,
  ReportOff,
  SettingsInputAntenna,
} from "@mui/icons-material";
import { Button, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { definedKeys, events, users } from "store";

import { useStore } from "../../context/store";
import { Uploads } from "../Uploads";
import { Broadcasts } from "./Broadcasts";
import { Issues } from "./Issues";
import { Taker } from "./Taker";
import { Thumbnail } from "./Thumbnail";
import { Times } from "./Times";

// TODO: remove after deploy
localStorage.removeItem("date_selected_tab");

export const Date: React.FC = () => {
  const { state, dispatch } = useStore();
  const params = useParams();

  const eid = parseInt(params.eid ?? "");
  const dateId = parseInt(params.dateId ?? "");

  const currentUser = users.currentUserSelector(state);
  const event = events.eventSelector(state, { eid });
  const date = events.dateSelector(state, { eid, dateId });
  const takers = events.takersSelector(state, { eid });

  const [processing, setProcessing] = useState(false);

  const handleStartStop = () => {
    if (processing) return;
    setProcessing(true);

    const nextStatus = (() => {
      switch (date.status) {
        case "created":
        case "stopped":
          return "started";
        case "started":
          return "stopped";
      }
    })();

    const action = events.updatedStatusProducer({
      eid,
      dateId,
      status: nextStatus,
    });
    dispatch(action)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const [tab, setTab] = useState(0);

  const activeIssues = definedKeys(date.issues).some(
    (issueId) => state.issues[issueId]?.status === "pending"
  );
  const anyFiles =
    definedKeys(state.uploads.events[eid]?.dates[dateId]?.uploads ?? {})
      .length > 0;

  const feedButtonProps = {
    created: { children: "Start Line Up", color: "success" },
    started: { children: "Stop World Feed", color: "error" },
    stopped: { children: "Restart World Feed", color: "warning" },
  } as const;

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{event.name}</Typography>
        {currentUser?.siteAdmin ? (
          <Button
            variant="outlined"
            size="large"
            onClick={handleStartStop}
            {...feedButtonProps[date.status]}
          />
        ) : (
          <Typography component="p" variant="h3" color="text.secondary">
            {date.status}
          </Typography>
        )}
      </Stack>

      <Times {...{ eventId: eid, dateId }} />

      <Divider />

      <Tabs
        value={tab}
        variant="fullWidth"
        onChange={(_, index) => setTab(index)}
      >
        <Tab icon={<SettingsInputAntenna />} label="Broadcasts" />
        <Tab
          disabled={!anyFiles && !currentUser?.siteAdmin}
          icon={anyFiles ? <FileDownload /> : <FileDownloadOff />}
          label="Files"
        />
        <Tab
          disabled={!activeIssues && !currentUser?.siteAdmin}
          icon={activeIssues ? <Report /> : <ReportOff />}
          label="Issues"
        />
        <Tab icon={<Chat />} label="Feedback" />
        <Tab icon={<PersonalVideo />} label="Preview" />
      </Tabs>

      {
        [
          <Broadcasts {...{ dateId, eid }} />,
          <Uploads eid={eid} dateId={dateId} />,
          <Issues eid={eid} dateId={dateId} />,
          takers.map(({ oid }) => (
            <Taker eid={eid} dateId={dateId} oid={oid} />
          )),
          <Thumbnail {...{ eid, dateId }} />,
        ][tab]
      }
    </Stack>
  );
};
