import {
  Alert,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { users } from "store";
import { useStore } from "../../context/store";

import { formatMilitaryTime, formatWithWeekday } from "../../utils/dates";
import { TimeSetter } from "./TimesSetter";

type Props = {
  eventId: number;
  dateId: number;
};

export const Times: React.FC<Props> = ({ eventId, dateId }) => {
  const { state } = useStore();

  const [updatingTimes, setUpdatingTimes] = useState(false);

  const currentUser = users.currentUserSelector(state);

  const date = state.events[eventId]?.dates[dateId];

  if (date === undefined) {
    return <Alert severity="error">Event date not found</Alert>;
  }

  const lineUpDate = formatWithWeekday(date.times.lineUp);
  const startDate = formatWithWeekday(date.times.start);
  const endDate = formatWithWeekday(date.times.end);

  return (
    <Stack direction="row">
      <Box flexGrow={0}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{formatWithWeekday(date.times.lineUp)}</TableCell>
              <TableCell align="left">Time GMT</TableCell>
              {currentUser?.siteAdmin && (
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    onClick={() => setUpdatingTimes(true)}
                  >
                    Update Times
                  </Button>
                  <TimeSetter
                    open={updatingTimes}
                    onClose={() => setUpdatingTimes(false)}
                    eid={eventId}
                    dateId={dateId}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Line Up:</TableCell>
              <TableCell>{formatMilitaryTime(date.times.lineUp)}</TableCell>
              <TableCell>-{/* date already in header */}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Feed Start:</TableCell>
              <TableCell>{formatMilitaryTime(date.times.start)}</TableCell>
              <TableCell>
                {startDate !== lineUpDate ? startDate : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Feed End:</TableCell>
              <TableCell>{formatMilitaryTime(date.times.end)}</TableCell>
              <TableCell>{endDate !== startDate ? endDate : "-"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Stack>
  );
};
