import { AdminPanelSettings } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { organizations, State } from "store";

type Props = {
  state: State;
};

export const memberships = ({ state }: Props): GridColDef => ({
  field: "memberships",
  headerName: "Memberships",
  valueGetter: getter(state),
  renderCell: render,
  minWidth: 300,
  flex: 1,
});

type Value = Array<{ name: string; orgAdmin: boolean }>;

const getter =
  (state: State) =>
  (params: GridValueGetterParams): Value => {
    const uid = Number(params.id);
    const oids = organizations.organizationIdsSelector(state).filter((oid) => {
      const organization = organizations.organizationSelector(state, oid);
      return organization && uid in organization.users;
    });
    return oids
      .map((oid) => organizations.organizationSelector(state, oid))
      .map((org) => ({
        ...org!,
        orgAdmin: org?.users[uid]?.orgAdmin || false,
      }));
  };

const render = (params: GridRenderCellParams<Value>) => {
  const orgs = params.value;
  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {orgs.map(({ name, orgAdmin }) => (
        <Chip
          icon={orgAdmin ? <AdminPanelSettings /> : undefined}
          label={name}
        />
      ))}
    </Stack>
  );
};
