import { Stack } from "@mui/material";
import { events, users } from "store";

import { useStore } from "../../context/store";
import { Adder } from "./Adder";
import { Taker } from "./Taker";

type Props = {
  eid: number;
};

export const Takers: React.FC<Props> = ({ eid }) => {
  const { state } = useStore();

  const currentUser = users.currentUserSelector(state);
  const takerOids = events.takerOidsSelector(state, { eid });

  // only show to regular users if they are part of multiple taker organizations
  if (!currentUser?.siteAdmin && takerOids.length < 2) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {takerOids.map((oid) => (
        <Taker key={oid} {...{ eid, oid }} />
      ))}
      {currentUser?.siteAdmin && <Adder {...{ eid }} />}
    </Stack>
  );
};
