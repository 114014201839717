"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRestrictor = void 0;
var broadcasts_1 = require("./broadcasts");
var chats_1 = require("./chats");
var emails_1 = require("./emails");
var events_1 = require("./events");
var feedback_1 = require("./feedback");
var issues_1 = require("./issues");
var organizations_1 = require("./organizations");
var sessions_1 = require("./sessions");
var thumbnails_1 = require("./thumbnails");
var uploads_1 = require("./uploads");
var users_1 = require("./users");
var accessRestrictor = function (_a) {
    var _b;
    var sid = _a.sid, state = _a.state;
    var session = (0, sessions_1.sessionSelector)(state, sid);
    var uid = (session === null || session === void 0 ? void 0 : session.status) === "identified" ? session.uid : null;
    var oids = uid
        ? (0, organizations_1.organizationIdsSelector)(state).filter(function (oid) {
            var organization = (0, organizations_1.organizationSelector)(state, oid);
            return organization && uid in organization.users;
        })
        : [];
    var siteAdmin = (uid && ((_b = (0, users_1.userSelector)(state, uid)) === null || _b === void 0 ? void 0 : _b.siteAdmin)) || false;
    var eids = (0, events_1.eventsSelector)(state)
        .filter(function (_a) {
        var takers = _a.takers;
        return Object.keys(takers)
            .map(Number)
            .some(function (oid) { return oids.includes(oid); });
    } // .some(oids.includes) causes TypeError
    )
        .map(function (_a) {
        var eid = _a.eid;
        return eid;
    });
    var code = (session === null || session === void 0 ? void 0 : session.status) === "invited" ? session.code : null;
    var target = { code: code, sid: sid, uid: uid, oids: oids, eids: eids, siteAdmin: siteAdmin };
    return {
        broadcasts: (0, broadcasts_1.accessRestrictor)({ state: state.broadcasts, target: target }),
        chats: (0, chats_1.accessRestrictor)({ state: state.chats, target: target }),
        emails: (0, emails_1.accessRestrictor)({ state: state.emails, target: target }),
        events: (0, events_1.accessRestrictor)({ state: state.events, target: target }),
        feedback: (0, feedback_1.accessRestrictor)({ state: state.feedback, target: target }),
        issues: (0, issues_1.accessRestrictor)({ state: state.issues, target: target }),
        organizations: (0, organizations_1.accessRestrictor)({
            state: state.organizations,
            target: target,
        }),
        sessions: (0, sessions_1.accessRestrictor)({ state: state.sessions, target: target }),
        thumbnails: (0, thumbnails_1.accessRestrictor)({ state: state.thumbnails, target: target }),
        uploads: (0, uploads_1.accessRestrictor)({ state: state.uploads, target: target }),
        users: (0, users_1.accessRestrictor)({ state: state.users, target: target }),
    };
};
exports.accessRestrictor = accessRestrictor;
