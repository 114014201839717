import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { useStore } from "../../context/store";

type Props = {
  emailId: number;
};

export const Message: React.FC<Props> = ({ emailId }) => {
  const { state } = useStore();

  const message = state.emails[emailId];

  if (message === undefined) {
    return <Paper variant="outlined">MESSAGE NOT FOUND</Paper>;
  }

  return (
    <Stack component={Paper} p={1} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Line {...{ title: "from", value: message.from }} />
        <Typography color="text.secondary">{message.date}</Typography>
      </Stack>
      {(["to", "subject"] as const).map((key) => (
        <Line {...{ title: key, value: message[key] }} />
      ))}
      {message.error && (
        <Typography color="error">Error: {message.error}</Typography>
      )}
      {message.testMessageUrl && (
        <Link
          href={message.testMessageUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          preview this test message
        </Link>
      )}
      <Paper variant="outlined">
        <Box p={1} dangerouslySetInnerHTML={{ __html: `${message.html}` }} />
      </Paper>
    </Stack>
  );
};

const Line: React.FC<{ title: string; value: string }> = ({
  title: key,
  value,
}) => (
  <Stack direction="row" spacing={1}>
    <Typography fontWeight="bold">{key}:</Typography>
    <Typography>{value}</Typography>
  </Stack>
);
