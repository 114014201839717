import produce from "immer";
import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import {
  ACTION_EVENT,
  initialState,
  State,
  STATE_EVENT,
  update,
  sessions,
  TOKEN_KEY,
  ClientRef,
  ClientProducible,
  Action,
  thumbnails,
  THUMBNAIL_EVENT,
} from "store";

import { defer, Defer } from "../utils/defer";
import { HOC } from "../utils/hoc";

const uri: string = (() => {
  if (!process.env.REACT_APP_NETLIFY) {
    return "http://localhost:3001";
  }
  if (process.env.REACT_APP_CONTEXT === "production") {
    return "https://rights-booking.herokuapp.com/";
  }
  if (process.env.REACT_APP_CONTEXT === "deploy-preview") {
    return `https://rights-booking-pr-${process.env.REACT_APP_REVIEW_ID}.herokuapp.com/`;
  }
  throw Error("No uri exists for this case");
})();

const socket = io(uri);

export type Dispatch = (
  action: ClientProducible<Action> & ClientRef
) => Promise<void>;
type Store = {
  state: State;
  dispatch: Dispatch;
  getState: () => State;
  getThumbnail: (request: thumbnails.Request) => Promise<thumbnails.Response>;
};

const uninitialized = () => {
  throw Error("Store context uninitialized");
};
const StoreContext = createContext<Store>({
  state: initialState,
  dispatch: uninitialized,
  getState: uninitialized,
  getThumbnail: uninitialized,
});

let _ref = 1;
const ref = () => `${socket.id}-${String(_ref++)}`;

const thumbnailRef = (_: { thumbnailId: number }) =>
  `thumbnail-${_.thumbnailId}`;

let _state = initialState;

const defers: Record<string, Defer<void>> = {};
const thumbnailDefers: Record<string, Defer<thumbnails.Response> | undefined> =
  {};

export const StoreContextProvider: HOC = ({ children }) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    const listener = ({
      clientRef,
      error,
      state,
    }: {
      clientRef: string | null;
      error: string | null;
      state: State;
    }) => {
      _state = state;
      setState((previousState) =>
        produce(previousState, (draft) => {
          update(draft, state);
        })
      );

      if (clientRef !== null) {
        const defer = defers[clientRef];

        if (error !== null) {
          defer.reject(error);
        } else {
          defer.resolve();
        }
        delete defers[clientRef];
      }
    };
    socket.on(STATE_EVENT, listener);
    return () => {
      socket.removeListener(STATE_EVENT, listener);
    };
  }, []);

  const dispatch: Dispatch = (action) => {
    const clientRef = ref();
    defers[clientRef] = defer();
    socket.emit(ACTION_EVENT, { ...action, clientRef });
    return defers[clientRef].promise;
  };

  const session = sessions.currentSessionSelector(state);
  useEffect(() => {
    if (session?.usedToken) {
      localStorage.setItem(TOKEN_KEY, session.usedToken);
    }
  }, [session]);

  const getState = () => _state;

  useEffect(() => {
    const listener = (data: unknown) => {
      try {
        const response = thumbnails.parseResponse(data);
        const { thumbnailId } = response;
        if (typeof thumbnailId !== "number") {
          console.error("Non-number thumbnail id received");
          return;
        }
        const ref = thumbnailRef({ thumbnailId });
        const defer = thumbnailDefers[ref];
        defer?.resolve(response);
        delete thumbnailDefers[ref];
      } catch (error) {
        console.error(error);
      }
    };

    socket.on(THUMBNAIL_EVENT, listener);
    return () => {
      socket.removeListener(THUMBNAIL_EVENT, listener);
    };
  }, []);

  const getThumbnail = async (
    request: thumbnails.Request
  ): Promise<thumbnails.Response> => {
    const ref = thumbnailRef(request);
    thumbnailDefers[ref] = defer();
    socket.emit(THUMBNAIL_EVENT, request);
    return thumbnailDefers[ref]!.promise;
  };

  return (
    <StoreContext.Provider value={{ state, dispatch, getState, getThumbnail }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
