import { LocalizationProvider as MuiLocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { de } from "date-fns/locale";

import { HOC } from "../utils/hoc";

export const LocalizationProvider: HOC = ({ children }) => (
  <MuiLocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
    {children}
  </MuiLocalizationProvider>
);
