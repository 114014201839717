import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import { thumbnails } from "store";

import { useStore } from "../../context/store";
import { dialogTextFieldProps } from "../../utils/componentProps";

type Props = {
  eid: number;
  dateId: number;
  open: boolean;
  onClose: () => void;
};

export const ThumbnailSetter: React.FC<Props> = (props) => {
  const { dispatch, state } = useStore();

  const [processing, setProcessing] = useState(false);

  const [url, setUrl] = useState("");
  const [intervalMs, setIntervalMs] = useState(1000);
  const [width, setWidth] = useState(1280);

  useEffect(() => {
    if (!props.open || processing) {
      return;
    }
    const thumbnailId =
      state.events[props.eid]?.dates[props.dateId]?.thumbnailId;
    const thumbnail = thumbnailId && state.thumbnails[thumbnailId];
    if (!thumbnail) {
      return;
    }
    setUrl(thumbnail.url ?? "");
    setIntervalMs(thumbnail.intervalMs);
    setWidth(thumbnail.width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const handleClose = () => {
    if (!processing) {
      props.onClose();
    }
  };

  const submittable = url && intervalMs > 0 && width > 0;

  const handleSubmit = () => {
    if (!submittable || processing) {
      return;
    }
    setProcessing(true);
    const action = thumbnails.sourceSetProducer({
      ...props,
      url,
      intervalMs,
      width,
    });
    dispatch(action)
      .then(props.onClose)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Dialog
      open={props.open || processing}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Set Thumbnail Source</DialogTitle>

      <DialogContent>
        <TextField
          label="Url"
          placeholder="https://iss.intinor.com/api/v1/units/D0****/video_inputs/0/thumbnails/0"
          {...dialogTextFieldProps({ setValue: setUrl, value: url })}
          helperText={
            url.includes("?")
              ? "Found symbol '?'. Please remove any parameters from the url."
              : " "
          }
        />
        <TextField
          label="Interval (ms)"
          {...dialogTextFieldProps({
            setValue: (value) => setIntervalMs(Number(value)),
            value: String(intervalMs),
          })}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          helperText=" "
        />
        <TextField
          label="Width (px)"
          {...dialogTextFieldProps({
            setValue: (value) => setWidth(Number(value)),
            value: String(width),
          })}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          helperText=" "
        />
      </DialogContent>

      <DialogActions>
        <Button color="warning" disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="success"
          disabled={!submittable || processing}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
