import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import { users } from "store";
import { useStore } from "../../context/store";
import { ThumbnailSetter } from "./ThumbnailSetter";

// TODO: remove after deploy
localStorage.removeItem("thumbnail_data");

type Props = {
  eid: number;
  dateId: number;
};

export const Thumbnail: React.FC<Props> = (props) => {
  const { state, getThumbnail } = useStore();

  const currentUser = users.currentUserSelector(state);

  const [setterOpen, setSetterOpen] = useState(false);

  const thumbnailId = state.events[props.eid]?.dates[props.dateId]?.thumbnailId;
  const thumbnail = thumbnailId && state.thumbnails[thumbnailId];

  const [data, setData] = useState("");
  const [error, setError] = useState("");

  const retrieve = useCallback(() => {
    if (!thumbnail) {
      return;
    }
    getThumbnail({ thumbnailId }).then((response) => {
      if ("data" in response) {
        setData(response.data);
      }
      if ("error" in response) {
        setError(response.error);
      }
    });
  }, [thumbnail, thumbnailId, getThumbnail]);

  useEffect(() => {
    if (!thumbnail) {
      return;
    }
    retrieve();
    const interval = setInterval(retrieve, thumbnail.intervalMs);
    return () => clearInterval(interval);
  }, [thumbnail, retrieve]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">{!thumbnail ? "No " : ""}Thumbnail</Typography>
        {currentUser?.siteAdmin && (
          <>
            <Button onClick={() => setSetterOpen(true)}>Set Source</Button>
            <ThumbnailSetter
              {...props}
              open={setterOpen}
              onClose={() => setSetterOpen(false)}
            />
          </>
        )}
      </Stack>
      {thumbnail &&
        (error ? (
          <Alert severity="error">
            <AlertTitle>Non-PNG Response:</AlertTitle>
            {error}
          </Alert>
        ) : data ? (
          <img
            width={thumbnail.width}
            src={`data:image/jpeg;base64,${data}`}
            alt="Thumbnail"
          />
        ) : (
          <Alert severity="info">
            <AlertTitle>Loading...</AlertTitle>
          </Alert>
        ))}
    </Stack>
  );
};
