import {
  AppBar,
  Button,
  Dialog,
  DialogTitle,
  LinearProgress,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { sessions, TOKEN_KEY, users } from "store";

import { useStore } from "../context/store";
import { DarkModeToggle } from "./DarkModeToggle";

export const Bar: React.FC = () => {
  const { state, dispatch } = useStore();

  const user = users.currentUserSelector(state);
  const authenticated = user !== undefined;

  const buttonStyle = {
    variant: "text",
    color: "inherit",
    size: "large",
  } as const;

  const stackStyle = {
    direction: "row",
    spacing: 2,
    alignItems: "center",
  } as const;

  const logoFilename = (() => {
    const subdomain = window.location.hostname.split(".")[0];
    switch (subdomain) {
      case "ucom":
        return "ucom-media.png";
      default:
        return "logo400.png";
    }
  })();

  const StackLeft = () => (
    <Stack {...stackStyle}>
      <img
        alt="Logo"
        height={40}
        src={`${process.env.PUBLIC_URL}/${logoFilename}`}
      />
      <Link
        variant="h1"
        color="inherit"
        underline="none"
        component={RouterLink}
        to="/"
      >
        Rights Booking
      </Link>
    </Stack>
  );

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    if (!authenticated || loggingOut) {
      return;
    }
    setLoggingOut(true);
    const action = sessions.loggedOutProducer({
      token: localStorage.getItem(TOKEN_KEY),
    });
    dispatch(action)
      .then(() => localStorage.removeItem(TOKEN_KEY))
      .catch(alert)
      .then(() => setLoggingOut(false));
  };

  const StackRight = () =>
    authenticated ? (
      <Stack {...stackStyle}>
        <Typography component="p" variant="h5">
          Hello, {user.name}!
        </Typography>
        <Button {...buttonStyle} onClick={handleLogout}>
          Logout
        </Button>
        <Dialog open={loggingOut}>
          <LinearProgress variant="indeterminate" />
          <DialogTitle>Logging out...</DialogTitle>
        </Dialog>
        <DarkModeToggle />
      </Stack>
    ) : (
      <Stack {...stackStyle}>
        <Button {...buttonStyle} component={RouterLink} to="login">
          Login
        </Button>
        <Button {...buttonStyle} component={RouterLink} to="register">
          Register
        </Button>
        <DarkModeToggle />
      </Stack>
    );

  const deployHeader: string = (() => {
    if (!process.env.REACT_APP_NETLIFY) {
      return "LOCALHOST";
    }
    if (process.env.REACT_APP_CONTEXT === "production") {
      return "";
    }
    if (process.env.REACT_APP_CONTEXT === "deploy-preview") {
      return `DEPLOY PREVIEW ${process.env.REACT_APP_REVIEW_ID}`;
    }
    return "ERROR";
  })();

  return (
    <AppBar position="static">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        component={Toolbar}
      >
        <StackLeft />
        {deployHeader && <Typography>{deployHeader}</Typography>}
        <StackRight />
      </Stack>
    </AppBar>
  );
};
