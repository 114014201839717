"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.initialState = {
    broadcasts: { fields: {}, groups: {}, order: [], values: {} },
    chats: {},
    emails: {},
    events: {},
    feedback: {},
    issues: {},
    organizations: {},
    sessions: { current: {}, tokens: {} },
    thumbnails: {},
    uploads: { events: {} },
    users: {},
};
