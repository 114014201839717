import { Delete, InsertDriveFile } from "@mui/icons-material";
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { uploads, users } from "store";

import { useStore } from "../context/store";
import { Uploader } from "./Uploader";

type Props = {
  eid: number;
  dateId: number | null;
};

export const Uploads: React.FC<Props> = ({ eid, dateId }) => {
  const { state, dispatch, getState } = useStore();

  const [uploadingFile, setUploadingFile] = useState(false);

  const currentUser = users.currentUserSelector(state);

  const uploadIds = uploads.idsSelector(state, { eid, dateId });

  const entries = uploadIds.map((uploadId) => ({
    uploadId,
    ...uploads.uploadSelector(state, {
      eid,
      dateId: dateId ?? undefined,
      uploadId,
    }),
  }));

  const handleDownload = (uploadId: number) => {
    const action = uploads.downloadedProducer({ eid, dateId, uploadId });
    dispatch(action)
      .then(() => {
        const state = getState();
        const url = uploads.downloadUrlSelector(state, {
          eid,
          dateId,
          uploadId,
        });
        if (url === undefined) {
          throw Error("No download url available");
        }

        const { name } = uploads.uploadSelector(state, {
          eid,
          dateId: dateId ?? undefined,
          uploadId,
        })!;
        const anchor = Object.assign(document.createElement("a"), {
          href: url,
          download: name,
          target: "_blank",
          rel: "noopener noreferrer",
          style: { display: "none" },
        });
        document.body.append(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch(alert);
  };

  const handleDelete = (uploadId: number) => {
    const action = uploads.deletedProducer({ eid, dateId, uploadId });
    dispatch(action);
  };

  return (
    <>
      <Typography variant="h3">Uploads</Typography>
      {currentUser?.siteAdmin && (
        <>
          <Button variant="outlined" onClick={() => setUploadingFile(true)}>
            Upload File
          </Button>
          <Uploader
            eid={eid}
            dateId={dateId}
            open={uploadingFile}
            onClose={() => setUploadingFile(false)}
          />
        </>
      )}
      <List>
        {entries.map(({ name, description, key, uploadId }) => (
          <ListItem key={key}>
            <IconButton
              aria-label="delete"
              onClick={() => handleDelete(uploadId)}
            >
              <Delete />
            </IconButton>
            <ListItemButton onClick={() => handleDownload(uploadId)}>
              <ListItemAvatar>
                <Avatar>
                  <InsertDriveFile />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} secondary={description} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};
