import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { isEmail, sessions, users } from "store";

import { useStore } from "../context/store";

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch, state } = useStore();
  const theme = useTheme();

  const authenticated = users.currentUserSelector(state) !== undefined;

  const [open, setOpen] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  const emailValid = useMemo(() => isEmail(email), [email]);

  if (authenticated) {
    return <Navigate to="/" />;
  }

  const handleSubmit = () => {
    setProcessing(true);
    const action = sessions.authenticatedProducer({ email, password });
    dispatch(action).catch((e) => {
      setError(String(e));
      setProcessing(false);
    });
  };

  const handleClose = () => {
    if (!processing) {
      setOpen(false);
      setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Login</DialogTitle>

      {/* autofill from pw-manager causes momentary overflow */}
      <Box component={DialogContent} overflow="hidden">
        <TextField
          autoFocus
          label="Email Address"
          type="email"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          // non-empty string prevents ui changing in size
          helperText={emailValid ? " " : "Please enter a valid email address"}
          error={!!error}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          error={!!error}
          // non-empty string prevents ui changing in size
          helperText={error || " "}
          onKeyDown={({ code }) =>
            emailValid && !!password && code === "Enter" && handleSubmit()
          }
        />
      </Box>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={!emailValid || !password || processing}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
