import { DateTimePicker } from "@mui/lab";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

import { events } from "store";

import { useStore } from "../../context/store";

type Props = {
  open: boolean;
  onClose: () => void;
  eid: number;

  prefill?: { start: string; details: string };
};

export const Adder: React.FC<Props> = (props) => {
  const { dispatch } = useStore();

  const [processing, setProcessing] = useState(false);

  const [start, setStart] = useState<string | null>(null);
  const [details, setDetails] = useState("");

  // this dialog needs to be reusable
  useEffect(() => {
    // don't overwrite if still processing
    if (!props.open || processing) {
      return;
    }
    setStart(props.prefill?.start || new Date().toUTCString());
    setDetails(props.prefill?.details || "");
  }, [processing, props]);

  const handleClose = () => !processing && props.onClose();

  const submittable = start !== null;

  const handleSubmit = () => {
    if (!submittable || processing) return;
    setProcessing(true);
    const action = events.addedDateProducer({ eid: props.eid, start, details });
    dispatch(action)
      .then(props.onClose)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const textFieldProps = {
    type: "text",
    variant: "outlined",
    fullWidth: true,
    margin: "dense",
    disabled: processing,
    onKeyDown: ({ code }: { code: string }) =>
      code === "Enter" && handleSubmit(),
  } as const;

  return (
    <Dialog
      open={props.open || processing}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Add Event</DialogTitle>

      <DialogContent>
        <DateTimePicker
          label="Start"
          value={start}
          onChange={(_date) => {
            const date = _date as unknown as Date;
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            setStart(date.toUTCString());
          }}
          renderInput={(params) => (
            <TextField autoFocus {...textFieldProps} {...params} />
          )}
        />

        <TextField
          autoFocus
          label="Details"
          {...textFieldProps}
          onKeyDown={undefined}
          multiline
          rows={10}
          value={details}
          onChange={({ target: { value } }) => setDetails(value)}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!submittable || processing} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
