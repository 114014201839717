import { Delete } from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { events, users } from "store";

import { useStore } from "../../context/store";
import { visibleOnHover } from "../../utils/componentProps";
import { formatWithoutSeconds } from "../../utils/dates";
import { Adder } from "../dates/Adder";
import { Takers } from "../takers";
import { Uploads } from "../Uploads";

export const Event: React.FC = () => {
  const { dispatch, state } = useStore();
  const params = useParams();

  const currentUser = users.currentUserSelector(state);

  const eid = parseInt(params.eid ?? "");
  const event = events.eventSelector(state, { eid });
  const dates = events.datesSelector(state, { eid });

  const [addingDate, setAddingDate] = useState(false);

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{event.name}</Typography>
      <Takers {...{ eid }} />
      <Divider />

      <Uploads eid={eid} dateId={null} />

      <Divider />

      {currentUser?.siteAdmin && (
        <>
          <Button variant="outlined" onClick={() => setAddingDate(true)}>
            Add Date
          </Button>
          <Adder
            eid={eid}
            open={addingDate}
            onClose={() => setAddingDate(false)}
          />
        </>
      )}

      <List>
        {dates.map(({ dateId, times: { start, lineUp, end }, status }) => (
          <ListItem key={dateId} sx={visibleOnHover(".remove-event")}>
            <ListItemButton component={Link} to={`dates/${dateId}`}>
              <ListItemText
                primary={formatWithoutSeconds(start)}
                secondary={status}
              />
              <Stack>
                <ListItemText
                  secondary={`Line-Up: ${formatWithoutSeconds(lineUp)}`}
                />
                <ListItemText secondary={`End: ${formatWithoutSeconds(end)}`} />
              </Stack>
            </ListItemButton>
            {currentUser?.siteAdmin && (
              <IconButton
                className="remove-event"
                aria-label="remove"
                onClick={() =>
                  dispatch(events.removedDateProducer({ eid, dateId })).catch(
                    alert
                  )
                }
              >
                <Delete />
              </IconButton>
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
