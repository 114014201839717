export const formatWithoutSeconds = (date: string) =>
  new Date(date).toLocaleString([], {
    timeZone: "UTC",
    dateStyle: "long",
    timeStyle: "short",
  }) + " GMT";

// FRI Nov 26th, 2021
export const formatWithWeekday = (input: string) => {
  const date = new Date(input);

  const dayAbbrev = weekday(date).slice(0, 3).toUpperCase();
  const monthAbbrev = month(date).slice(0, 3);
  const dateOrdinal = ordinal(date.getUTCDate());
  const year = date.getUTCFullYear();

  return `${dayAbbrev} ${monthAbbrev} ${dateOrdinal}, ${year}`;
};

// 1300
export const formatMilitaryTime = (input: string) => {
  const date = new Date(input);
  return `${twoDigits(date.getUTCHours())}${twoDigits(date.getUTCMinutes())}`;
};

/** UTILITIES */

const twoDigits = (input: number) => input.toString().padStart(2, "0");

const ordinal = (date: number) => {
  switch (date) {
    case 1:
    case 21:
    case 31:
      return `${date}st`;
    case 2:
    case 22:
      return `${date}nd`;
    case 3:
    case 23:
      return `${date}rd`;
    default:
      return `${date}th`;
  }
};

const month = (date: Date) => {
  // toLocaleString('en-us', {timeZone: "UTC", month: "long" })
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[date.getUTCMonth()];
};

const weekday = (date: Date) => {
  // toLocaleString('en-us', {timeZone: "UTC", weekday: "long"})
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekdays[date.getUTCDay()];
};
