import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Navigate } from "react-router";

import { events, users } from "store";

import { useStore } from "../../context/store";
import { Adder } from "./Adder";
import { Previewer } from "./Previewer";

export const Events: React.FC = () => {
  const { state } = useStore();

  const currentUser = users.currentUserSelector(state);
  const eids = events.eventsSelector(state).map(({ eid }) => eid);

  const [addingEvent, setAddingEvent] = useState(false);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h2">Events</Typography>

      {currentUser?.siteAdmin && (
        <>
          <Button variant="outlined" onClick={() => setAddingEvent(true)}>
            Add Event
          </Button>
          <Adder open={addingEvent} onClose={() => setAddingEvent(false)} />
        </>
      )}

      {eids.map((eid) => (
        <Previewer eid={eid} />
      ))}
    </Stack>
  );
};
