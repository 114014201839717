import React, { useState } from "react";
import { Dialog, DialogTitle, LinearProgress } from "@mui/material";

import { sessions, TOKEN_KEY, users } from "store";

import { useStore } from "../context/store";
import { useOnce } from "../utils/useOnce";

export const Identify: React.FC = () => {
  const { state, dispatch } = useStore();

  const authenticated = users.currentUserSelector(state) !== undefined;

  const [identifying, setIdentifying] = useState(false);
  useOnce(() => {
    if (authenticated) {
      return;
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (token === null) {
      return;
    }
    setIdentifying(true);
    const action = sessions.identifiedProducer({ token });
    dispatch(action)
      .catch(() => localStorage.removeItem(TOKEN_KEY))
      .then(() => setIdentifying(false));
  });

  return (
    <Dialog open={!authenticated && identifying} fullWidth maxWidth="xs">
      <LinearProgress variant="indeterminate" />
      <DialogTitle>Identifying...</DialogTitle>
    </Dialog>
  );
};
