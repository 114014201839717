import { IconButton, useTheme } from "@mui/material";
import React from "react";
import { useSetPaletteMode } from "../context/theme";

export const DarkModeToggle: React.FC = () => {
  const { palette } = useTheme();
  const { mode } = palette;
  const otherMode = mode === "light" ? "dark" : "light";

  const { setPaletteMode } = useSetPaletteMode();

  const handleClick = () => {
    setPaletteMode(otherMode);
  };

  return (
    <IconButton size="large" onClick={handleClick} aria-label="toggle darkmode">
      {mode === "light" ? "🌞" : "🌚"}
    </IconButton>
  );
};
