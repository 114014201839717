import { Button, ListItem, Stack, TextField } from "@mui/material";
import { useState } from "react";

import { broadcasts } from "store";

import { useStore } from "../../context/store";

type Props = {
  groupId: number;
};

export const FieldAdder: React.FC<Props> = ({ groupId }) => {
  const { dispatch } = useStore();

  const [name, setName] = useState("");

  const [processing, setProcessing] = useState(false);

  const submittable = !processing && !!name;

  const handleSubmit = () => {
    if (!submittable) return;
    setProcessing(true);
    const action = broadcasts.fieldAddedProducer({ name, groupId });
    dispatch(action)
      .then(() => setName(""))
      .catch(alert)
      .then(() => setProcessing(false));
  };

  return (
    <Stack component={ListItem} direction="row" alignItems="baseline">
      <TextField
        margin="dense"
        label="New Field"
        type="text"
        variant="standard"
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        disabled={processing}
        onKeyDown={({ code }) => code === "Enter" && handleSubmit()}
      />
      <Button
        onClick={handleSubmit}
        sx={{ visibility: submittable ? "visible" : "hidden" }}
      >
        Add Field
      </Button>
    </Stack>
  );
};
