import { Card, CardContent, List, Stack, Typography } from "@mui/material";
import { useStore } from "../../context/store";
import { Field } from "./Field";
import { FieldAdder } from "./FieldAdder";

type Props = {
  groupId: number;
};

export const Group: React.FC<Props> = ({ groupId }) => {
  const { state } = useStore();

  const group = state.broadcasts.groups[groupId]!;

  return (
    <Card>
      <CardContent component={Stack} spacing={2}>
        <Typography variant="h3">{group.title}</Typography>

        <List disablePadding>
          {group.elements.map((fieldId) => (
            <Field key={fieldId} {...{ fieldId }} />
          ))}

          <FieldAdder {...{ groupId }} />
        </List>
      </CardContent>
    </Card>
  );
};
