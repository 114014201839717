import { GridProps, TextFieldProps } from "@mui/material";
import { SxProps } from "@mui/system";

export const dialogTextFieldProps = (props: {
  onEnter?: () => void;
  setValue: (value: string) => void;
  value: string;
}): TextFieldProps => ({
  type: "text",
  variant: "outlined",
  fullWidth: true,
  margin: "dense",
  value: props.value,
  onChange: ({ target: { value } }) => props.setValue(value),
  onKeyDown:
    props.onEnter &&
    (({ code }) => {
      if (code === "Enter") {
        props.onEnter!();
      }
    }),
});

export const gridContainerProps = (props: { spacing: number }): GridProps => ({
  container: true,
  maxWidth: "100%",
  // spacing sets padding top and left on grid items
  spacing: props.spacing,
  pr: props.spacing,
  pb: props.spacing,
});

export const gridItemProps = (props: {
  firstBreakpoint: "xs" | "sm" | "md";
}): GridProps => {
  const breakpoints = (() => {
    switch (props.firstBreakpoint) {
      case "xs":
        return { xs: 6, sm: 4, md: 3, lg: 2, xl: 1 };
      case "sm":
        return { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };
      case "md":
        return { xs: 12, md: 6, lg: 4, xl: 3 };
    }
  })();

  return {
    item: true,
    ...breakpoints,
    sx: {
      "> *": {
        height: "100%",
      },
    },
  };
};

export const visibleOnHover = (selector: string): SxProps => ({
  [selector]: {
    visibility: "hidden",
  },
  "&:hover": {
    [selector]: {
      visibility: "visible",
    },
  },
});
