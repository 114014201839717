import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { users } from "store";

import { useStore } from "../context/store";

type InviteProps = {
  open: boolean;
  onClose: () => void;
};

export const Invite: React.FC<InviteProps> = ({ open, onClose }) => {
  const { dispatch } = useStore();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [siteAdmin, setSiteAdmin] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  // this dialog needs to be reusable
  useEffect(() => {
    // don't overwrite if still processing
    if (!open || processing) {
      return;
    }
    setName("");
    setPhone("");
    setSiteAdmin(false);
    setError("");
  }, [open, processing]);

  const submittable = !!name;

  const handleSubmit = () => {
    setProcessing(true);
    const action = users.invitedProducer({
      name,
      phone: phone || undefined,
      siteAdmin,
    });
    dispatch(action)
      .then(onClose)
      .catch((e) => setError(String(e)))
      .then(() => setProcessing(false));
  };

  const handleClose = () => !processing && onClose();

  return (
    <Dialog
      open={open || processing}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Invite</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          label="Name"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
          disabled={processing}
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          // non-empty string prevents ui changing in size
          helperText={name ? " " : "Full name (required)"}
          onKeyDown={({ code }) =>
            submittable && code === "Enter" && handleSubmit()
          }
        />

        <TextField
          label="Phone number"
          type="text"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={phone}
          onChange={({ target: { value } }) => setPhone(value)}
          // non-empty string prevents ui changing in size
          helperText={"(optional)"}
          onKeyDown={({ code }) =>
            submittable && code === "Enter" && handleSubmit()
          }
        />

        <FormControlLabel
          label="Make site admin"
          control={
            <Switch
              value={siteAdmin}
              onChange={({ target: { checked } }) => setSiteAdmin(checked)}
            />
          }
        />

        <DialogContentText>{error}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!submittable || processing} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
