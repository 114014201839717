import {
  Code,
  Email,
  Event,
  Group,
  Groups,
  ManageAccounts,
  Settings,
  SvgIconComponent,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { users } from "store";

import { useStore } from "../context/store";

const targets = (props: { userId?: number }) => [
  {
    Icon: Event,
    title: "Events",
    to: "events",
  },
  {
    Icon: ManageAccounts,
    title: "Profile",
    to: props.userId ? `users/${props.userId}` : "",
  },
];

const adminTargets = [
  {
    Icon: Group,
    title: "Users",
    to: "users",
  },
  {
    Icon: Groups,
    title: "Organizations",
    to: "organizations",
  },
  {
    Icon: Settings,
    title: "Broadcast Fields",
    to: "broadcast-fields",
  },
  {
    Icon: Email,
    title: "Emails",
    to: "emails",
  },
];

const expandTargets = (
  targets: Array<{ Icon: SvgIconComponent; title: string; to: string }>
) =>
  targets.map(({ Icon, title, to }) => (
    <ListItemButton key={to} component={RouterLink} to={to}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText>{title.replace(" ", "\xA0")}</ListItemText>
    </ListItemButton>
  ));

export const Menu: React.FC = () => {
  const { state } = useStore();

  const currentUser = users.currentUserSelector(state);
  const siteAdmin = currentUser?.siteAdmin ?? false;
  const userId = currentUser?.uid;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <List>
        {expandTargets(targets({ userId }))}
        <Divider />
        {siteAdmin && expandTargets(adminTargets)}
      </List>
      <Box flexGrow={1} />
      <List>
        <ListItemButton key="debug" component={RouterLink} to="debug">
          <ListItemIcon>
            <Code />
          </ListItemIcon>
          <ListItemText>Debug</ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
};
