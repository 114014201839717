import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemButton,
} from "@mui/material";
import { useState } from "react";

import { definedKeys, events } from "store";

import { useStore } from "../../context/store";

type Props = {
  eid: number;
};

export const Adder: React.FC<Props> = ({ eid }) => {
  const { state, dispatch } = useStore();

  const [adding, setAdding] = useState(false);
  const [processing, setProcessing] = useState(false);

  const event = state.events[eid];
  if (event === undefined) {
    return <Chip variant="outlined" color="error" label="EVENT UNDEFINED" />;
  }

  const oids = definedKeys(state.organizations);
  const takerOids = definedKeys(event.takers);
  const nonTakerOids = oids.filter((oid) => !takerOids.includes(oid));

  if (nonTakerOids.length === 0) {
    return null;
  }

  // FIXME: this keeps closing the dialog (rerendering the component?)
  const handleAdd = ({ oid }: { oid: number }) => {
    if (processing) {
      return;
    }
    setProcessing(true);
    const action = events.takerAddedProducer({ eid, oid });
    dispatch(action)
      .catch(alert)
      .then(() => setProcessing(false));
  };

  const handleClose = () => {
    if (processing) {
      return;
    }
    setAdding(false);
  };

  return (
    <>
      <Chip
        variant="outlined"
        label="Add Taker"
        onClick={() => setAdding(true)}
      />
      <Dialog
        open={adding || processing}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <LinearProgress
          {...(processing
            ? { variant: "indeterminate" }
            : { variant: "determinate", value: 0 })}
        />

        <DialogTitle>Add Taker to Event '{event?.name}'</DialogTitle>

        <DialogContent>
          {nonTakerOids.map((oid) => (
            <ListItemButton
              key={oid}
              disabled={processing}
              onClick={() => handleAdd({ oid })}
            >
              {state.organizations[oid]?.name ?? "ORGANIZATION UNDEFINED"}
            </ListItemButton>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={processing}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
