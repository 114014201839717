import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import { Outlet, Route, Routes } from "react-router-dom";

import { users } from "store";

import { useStore } from "../context/store";
import { Bar } from "./Bar";
import { BroadcastFields } from "./broadcast-fields";
import { Emails } from "./emails";
import { Event } from "./events/Event";
import { Date } from "./dates/Date";
import { Events } from "./events";
import { Identify } from "./Identify";
import { Login } from "./Login";
import { Menu } from "./Menu";
import { Organizations } from "./organizations";
import { Register } from "./Register";
import { Users } from "./users";
import { Profile } from "./users/Profile";

const Root: React.FC<{ currentUser?: users.User & { uid: number } }> = (
  props
) => (
  <Stack component={Paper} height="100vh">
    <Bar />
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" />}
      flexGrow={1}
      height="100%"
      overflow="hidden"
    >
      {props.currentUser && (
        <Box m={2} overflow-y="auto">
          <Menu />
        </Box>
      )}
      <Box m={2} flexGrow={1} overflow="auto" maxWidth="lg">
        <Outlet />
      </Box>
    </Stack>
  </Stack>
);

const App: React.FC = () => {
  const { state } = useStore();

  const currentUser = users.currentUserSelector(state);

  return (
    <>
      <Identify />
      <Routes>
        <Route path="/" element={<Root {...{ currentUser }} />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="invite">
            <Route index element={<Navigate to="/register" />} />
            <Route path=":code" element={<Register />} />
          </Route>
          <Route
            path="debug"
            element={
              <Typography component="pre" fontFamily="monospace">
                {JSON.stringify(state, null, 2)}
              </Typography>
            }
          />
          <Route
            path="organizations"
            element={currentUser && <Organizations />}
          />
          <Route path="users">
            <Route index element={currentUser && <Users />} />
            <Route path=":userId" element={currentUser && <Profile />} />
          </Route>
          <Route path="events">
            <Route index element={currentUser && <Events />} />
            <Route path=":eid">
              <Route index element={currentUser && <Event />} />
              <Route path="dates/:dateId" element={currentUser && <Date />} />
            </Route>
          </Route>
          <Route
            path="broadcast-fields"
            element={currentUser?.siteAdmin && <BroadcastFields />}
          />
          <Route path="emails" element={currentUser?.siteAdmin && <Emails />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
