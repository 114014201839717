import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { sessions, users, isEmail } from "store";

import { useStore } from "../context/store";

export const Register: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch, state } = useStore();
  const params = useParams();
  const theme = useTheme();

  const authenticated = users.currentUserSelector(state) !== undefined;

  // const [code, setCode] = useState<string | null>(null);
  const [codeError, setCodeError] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [open, setOpen] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  const emailValid = useMemo(() => isEmail(email), [email]);
  const submittable = name && emailValid && password;

  const [redeemedCode, setRedeemedCode] = useState(false);
  useEffect(() => {
    if (!redeemedCode && params.code !== undefined) {
      setRedeemedCode(true);
      setProcessing(true);
      const { code } = params;
      const action = sessions.redeemedProducer({ code });
      dispatch(action)
        .then(() => {
          const uid = users.userIdFromCodeSelector(state, code);
          const user = users.userSelector(state, uid!)!;
          setName(user.name);
          setPhone(user.phone || "");
        })
        .catch((e) => setCodeError(String(e)))
        .then(() => setProcessing(false));
    }
  }, [redeemedCode, params, dispatch, state]);

  if (authenticated) {
    return <Navigate to="/" />;
  }

  const handleSubmit = () => {
    setProcessing(true);

    const register = users.registeredProducer({
      name,
      email,
      // schema accepts non-empty string or undefined
      phone: phone || undefined,
      password,
      code: params.code && !codeError ? params.code : null,
    });
    const authenticate = sessions.authenticatedProducer({ email, password });

    dispatch(register)
      .then(() => dispatch(authenticate))
      .catch((e) => setError(String(e)))
      .then(() => setProcessing(false));
  };

  const handleClose = () => {
    if (!processing) {
      setOpen(false);
      setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <LinearProgress
        {...(processing
          ? { variant: "indeterminate" }
          : { variant: "determinate", value: 0 })}
      />

      <DialogTitle>Register</DialogTitle>

      <DialogContent>
        {params.code && (
          <DialogContentText>
            {codeError
              ? `Invite code invalid: ${codeError}. You can continue in order to register a new account.`
              : `Redeemed invite code successfully. You can change the provided information below.`}
          </DialogContentText>
        )}

        <TextField
          autoFocus
          label="Name"
          type="text"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          // non-empty string prevents ui changing in size
          helperText={name ? " " : "Full name (required)"}
        />

        <TextField
          label="Email Address"
          type="email"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          // non-empty string prevents ui changing in size
          helperText={emailValid ? " " : "Valid email address (required)"}
          error={!!error}
        />

        <TextField
          label="Phone number"
          type="text"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={phone}
          onChange={({ target: { value } }) => setPhone(value)}
          // non-empty string prevents ui changing in size
          helperText={"Phone number (optional)"}
        />

        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={processing}
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          error={!!error}
          // non-empty string prevents ui changing in size
          helperText={error || " "}
          onKeyDown={({ code }) =>
            submittable && code === "Enter" && handleSubmit()
          }
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={processing} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!submittable || processing} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
